import React from "react";
import { FaArrowTrendUp } from "react-icons/fa6";

const CardSix = ({ text, data }) => {
  return (
    <div className="card-six">
      <div className="d-flex align-items-center justify-content-between">
        <aside>
          <h2 className="card-title">{text.title}</h2>
        </aside>
        <aside>
          <p className="card-p">
            {text.parties}
            <span>
              <FaArrowTrendUp />
            </span>
          </p>
          <p className="card-p2">
            for<span>{text.seats}</span>
          </p>
        </aside>
      </div>
      <div className="card-table">
        <table
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                style={{
                  borderBottom:
                    index === data.length - 1 ? "none" : "1px solid #EFEFEF",
                }}
              >
                <td className="py-3">{item.party}</td>
                <td className="py-3 text-end">{item.votes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CardSix;
