import React from "react";
import { Dropdown, DropdownButton, Image } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loaders/loader";
import {
  logoutUser,
  indexGetAllManageElections,
} from "../../redux/user/action";
import OrangeSolidBtn from "../buttons/orangeSolidBtn";
const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

const DefaultHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminone.loading);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const isLoggedIn = !!token;
  const authTitle = isLoggedIn ? `hi, ${user.name}` : "Login/Sign Up";
  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/admin/login");
    localStorage.removeItem("token");
  };
  const onClick = () => {
    navigate("/simulator");
  };

  return (
    <header className="dheadermain">
      <Navbar expand="xl">
        <Container fluid>
          <Navbar.Brand as={Link} to="/" href="#home" className="logoBlk">
            <Image src={Logo} fluid />
          </Navbar.Brand>
          <div className="apsettingBtnblk">
            <DropdownButton
              className="UProfileBtn d-block d-xl-none"
              id="dropdown-basic-button"
              title={authTitle}
            >
              {isLoggedIn ? (
                <>
                  <Dropdown.Item
                    as={Link}
                    to="/select-election"
                    className="mUlist"
                  >
                    Dashboard
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/profile" className="mUlist">
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/login-perefrance"
                    className="mUlist"
                    onClick={handleLogout}
                  >
                    Log out
                  </Dropdown.Item>
                </>
              ) : (
                <>
                  <Dropdown.Item
                    as={Link}
                    className="mUlist"
                    to="/login-perefrance"
                  >
                    Log in
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} className="mUlist" to="/register">
                    Sign up
                  </Dropdown.Item>
                </>
              )}
            </DropdownButton>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <Nav.Link as={Link} to="/" className="anavLink">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/" className="anavLink">
                About us
              </Nav.Link>
              <Nav.Link as={Link} to="/blogs" className="anavLink">
                Blogs
              </Nav.Link>
              <Nav.Link as={Link} to="/election-results" className="anavLink">
                Election Results
              </Nav.Link>
              <Nav.Link as={Link} to="/reports" className="anavLink">
                Reports
              </Nav.Link>
              <Nav.Link as={Link} to="/candidates" className="anavLink">
                Candidates
              </Nav.Link>
              <Nav.Link as={Link} to="/parties" className="anavLink">
                Parties
              </Nav.Link>
              {/* <Nav.Link
                as={Link}
                to="/simulator"
                className="anavLink"
                style={{ color: "#f07e0c" }}
              >
                Vote Simulator
              </Nav.Link> */}
              <OrangeSolidBtn children={"Vote Simulator"} onClick={onClick} />
              <DropdownButton
                className="UProfileBtn d-none d-xl-block"
                id="dropdown-basic-button"
                title={authTitle}
              >
                {isLoggedIn ? (
                  <>
                    <Dropdown.Item
                      as={Link}
                      to="/select-election"
                      className="mUlist"
                    >
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/profile" className="mUlist">
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to="/login-perefrance"
                      className="mUlist"
                      onClick={handleLogout}
                    >
                      Log out
                    </Dropdown.Item>
                  </>
                ) : (
                  <>
                    <Dropdown.Item
                      as={Link}
                      className="mUlist"
                      to="/login-perefrance"
                    >
                      Log in
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} className="mUlist" to="/register">
                      Sign up
                    </Dropdown.Item>
                  </>
                )}
              </DropdownButton>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </header>
  );
};

export default DefaultHeader;
