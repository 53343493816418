import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import { indexParties } from "../../../redux/adminone/action";
import { Col, Container, Row } from "react-bootstrap";
import SearchBtnForm from "../../../components/form/searchBtnForm";
import CardThree from "../../../components/cards/cardThree";
import { cApiUrlPrefix } from "../../../config/envConfig";
import { formatDate } from "../../../utilities/common";
import { useNavigate } from "react-router-dom";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const PartiesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(indexParties());
  }, [dispatch]);

  const loading = useSelector((state) => state.adminone.loading);
  const indexPartiesData = useSelector(
    (state) => state.adminone.indexPartiesData?.data
  );
  const handleClick = (id) => {
    navigate("/parties-history", { state: { id } });
  };
  const props = {
    titles: {
      title1: "PARTIES",
    },
    childrens: {
      children1: "View More",
    },
    headings: {
      heading1: "Leader",
      heading2: "Founding Date",
      heading3: "Website",
    },
    handleClick,
  };

  return (
    <main className="parties-main">
      <Container fluid className="container-parties">
        <Row className="row-parties">
          <Col className="col-parties">
            <h2 className="parties-heading">{props.titles.title1}</h2>
          </Col>
          <Col className="col-parties">
            <SearchBtnForm
              className="minputSearch"
              placeholder="search party..."
            />
          </Col>
        </Row>
        <Row className="row-parties" xs={1}>
          {indexPartiesData &&
            indexPartiesData.map((party) => (
              <Col key={party.id} className="col-parties">
                <CardThree
                  src={cApiUrlPrefix + party.logo}
                  title={party.party_name}
                  leader={party.leader}
                  ideology={party.ideology}
                  headquarters={party.headquarters}
                  party_abbreviation={party.party_abbreviation}
                  website={party.website}
                  founding_date={formatDate(party.founding_date)}
                  childrens={props.childrens}
                  headings={props.headings}
                  onClick={() => props.handleClick(party.id)}
                />
              </Col>
            ))}
        </Row>
      </Container>

      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </main>
  );
};

export default PartiesPage;
