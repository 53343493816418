import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Box } from "rebass";
import "react-circular-progressbar/dist/styles.css";

const NewProgressBar = () => {
  const percentage = 88;
  return (
    <div className="orangeProgressBar">
      <Box id="container" style={{ width: 200, height: 120 }}>
        <Box
          sx={{
            height: 36,
            width: 36,
          }}
        >
          <CircularProgressbar
            value={percentage}
            circleRatio={0.5}
            strokeWidth={8}
            text={`${percentage}%`}
            styles={buildStyles({
              rotation: 0.75,
              pathColor: "#f88d1e",
              trailColor: "#C4C4C4",
              strokeLinecap: "round",
              textColor: "#000",
            })}
          />
          {/* <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) rotate(-135deg)",
              fontSize: "16px",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            {`${percentage}%`}
          </div> */}
        </Box>
      </Box>
    </div>
  );
};

export default NewProgressBar;
