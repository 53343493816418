import React from "react";
import BlueBorderBtn from "../buttons/blueBorderBtn";
import SearchBtnForm from "../form/searchBtnForm";
import ManageTable from "../table/manageTable";
import Cselect from "../select/cselect";

const ManageBlkFive = (props) => {
    return (
        <div className="manageBlock-five">
            <div className="mangeHead">
                <h2 className="manageHeadTitle">{props.pageTitles.pageTitle1}</h2>
                <BlueBorderBtn
                    children={props.childrens?.children1}
                    onClick={props.handleClick}
                />
            </div>
            <div className="mangeHeadss">
                <div className="d-flex align-items-center">
                    <label htmlFor="pageSizeSelect" className="mangeSl">
                        Page size:
                    </label>
                    <Cselect
                        id="pageSizeSelect"
                        options={props.options}
                        isSearchable={props.isSearchable}
                        className={props.className.cselectpageSize}
                        styles={props.cstomStyle}
                    />
                </div>

                <SearchBtnForm
                    className={props.className.minputSearch}
                    placeholder={props.Placeholder.Placeholder1}
                />
            </div>
            <div className="mangeHeadss border-0">
                <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <span>{props.label?.lable1}</span>
                        <span>{props.details?.detail1}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span>{props.label?.lable2}</span>
                        <span>{props.details?.detail2}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span>{props.label?.lable3}</span>
                        <span>{props.details?.detail3}</span>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <Cselect
                        id="pageSizeSelect"
                        options={props.options1}
                        isSearchable={props.isSearchable}
                        className={props.className.cselectpageSize}
                        styles={props.cstomStyle1}
                        onChange={props.selectChange}
                        placeholder={props.Placeholder.Placeholder2}

                    />
                    <Cselect
                        id="pageSizeSelect"
                        options={props.options2}
                        isSearchable={props.isSearchable}
                        className={props.className.cselectpageSize2}
                        styles={props.cstomStyle2}
                        placeholder={props.Placeholder.Placeholder3}
                        onChange={props.selectChange1}
                    />
                </div>
            </div>
            <ManageTable
                data={props.data}
                loading={props.loading}
                error={props.error}
                labels={props.labels}
            />
        </div>
    );
};

export default ManageBlkFive;
