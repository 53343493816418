import React from "react";

const Aside4 = (aside4) => {
    return (
        <div className="asidefour">
            <div className="asidefour-blk">
                <h2 className="asidefour-head">{aside4.pageTitle}</h2>
                <div className="asidefour-location">{aside4.location}</div>
            </div>
            <div className="asidefour-blk">
                <div className="asidefour-selectblk">{aside4.selectBlk1}</div>
                <div className="asidefour-selectblk">{aside4.selectBlk2}</div>
            </div>

        </div>
    );
};

export default Aside4;
