import ApiService from "../../config/apiService";
import {
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  FORGOT_PASSWORD_SEND_EMAIL_REQUEST,
  FORGOT_PASSWORD_SEND_EMAIL_SUCCESS,
  FORGOT_PASSWORD_SEND_EMAIL_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGOUT_USER,
  UPDATE_USERS_LOCATION_REQUEST,
  UPDATE_USERS_LOCATION_SUCCESS,
  UPDATE_USERS_LOCATION_FAILURE,
  POST_REGISTRTVOTERID_REQUEST,
  POST_REGISTRTVOTERID_SUCCESS,
  POST_REGISTRTVOTERID_FAILURE,
  ADD_USERS_LOCATION_REQUEST,
  ADD_USERS_LOCATION_SUCCESS,
  ADD_USERS_LOCATION_FAILURE,
  GET_FILTERELECTIONRESULTS_REQUEST,
  GET_FILTERELECTIONRESULTS_SUCCESS,
  GET_FILTERELECTIONRESULTS_FAILURE,
  GET_USERDASHBOARD_REQUEST,
  GET_USERDASHBOARD_SUCCESS,
  GET_USERDASHBOARD_FAILURE,
  ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_REQUEST,
  ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_SUCCESS,
  ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_FAILURE,
  GET_ELECTION_USERID_ELECTIONID_REQUEST,
  GET_ELECTION_USERID_ELECTIONID_SUCCESS,
  GET_ELECTION_USERID_ELECTIONID_FAILURE,
  // GET_USER_DETAILS_BY_ID_REQUEST,
  // GET_USER_DETAILS_BY_ID_SUCCESS,
  // GET_USER_DETAILS_BY_ID_FAILURE,
  UPDATE_EDIT_USER_BY_USER_ID_REQUEST,
  UPDATE_EDIT_USER_BY_USER_ID_SUCCESS,
  UPDATE_EDIT_USER_BY_USER_ID_FAILURE,
  UPDATE_EMAIL_BY_USER_ID_REQUEST,
  UPDATE_EMAIL_BY_USER_ID_SUCCESS,
  UPDATE_EMAIL_BY_USER_ID_FAILURE,
  GET_ALL_PRESIDENTIAL_SIMULATORS_REQUEST,
  GET_ALL_PRESIDENTIAL_SIMULATORS_SUCCESS,
  GET_ALL_PRESIDENTIAL_SIMULATORS_FAILURE,
  UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_REQUEST,
  UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_SUCCESS,
  UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_FAILURE,
  GET_WINNER_CALCULATE_VOTES_REQUEST,
  GET_WINNER_CALCULATE_VOTES_SUCCESS,
  GET_WINNER_CALCULATE_VOTES_FAILURE,
  GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_REQUEST,
  GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_SUCCESS,
  GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_FAILURE,
  GET_ALLCANDIDATERESULTS_BYID_REQUEST,
  GET_ALLCANDIDATERESULTS_BYID_SUCCESS,
  GET_ALLCANDIDATERESULTS_BYID_FAILURE,
  POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_REQUEST,
  POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_SUCCESS,
  POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_FAILURE,
  GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_REQUEST,
  GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_SUCCESS,
  GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_FAILURE,
  POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_REQUEST,
  POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_SUCCESS,
  POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_FAILURE,
} from "./actionTypes";
// Register user action start

export const registerUser = (credentials, callback) => async (dispatch) => {
  dispatch({ type: REGISTER_USER_REQUEST });
  try {
    const response = await ApiService.post("/api/register", credentials);
    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: REGISTER_USER_SUCCESS, payload: responsePayload });
    if (callback) {
      callback(null, responsePayload);
    }
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };

    dispatch({
      type: REGISTER_USER_FAILURE,
      payload: errorPayload,
    });
    if (callback) {
      callback(errorPayload, null);
    }
  }
};
// Register user action end

// verify Email by otp start
export const verfyEmailByOtp = (credentials, callback) => async (dispatch) => {
  dispatch({ type: VERIFY_EMAIL_REQUEST });

  try {
    const response = await ApiService.post("/api/verify", credentials);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: VERIFY_EMAIL_SUCCESS, payload: responsePayload });
    if (callback) {
      callback(null, responsePayload);
    }
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: VERIFY_EMAIL_FAILURE,
      payload: errorPayload,
    });
    if (callback) {
      callback(errorPayload, null);
    }
  }
};
// verify Email by otp end

// resend otp start
export const resendOTP = (credentials, callback) => async (dispatch) => {
  dispatch({ type: RESEND_OTP_REQUEST });

  try {
    const response = await ApiService.post("/api/resend-otp", credentials);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: RESEND_OTP_SUCCESS, payload: responsePayload });
    callback(null, responsePayload);
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: RESEND_OTP_FAILURE,
      payload: errorPayload,
    });
    callback(errorPayload, null);
  }
};
// resend otp end

// login user action start
export const loginUser = (credentials, callback) => async (dispatch) => {
  dispatch({ type: LOGIN_USER_REQUEST });

  try {
    const response = await ApiService.post("/api/login", credentials);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({ type: LOGIN_USER_SUCCESS, payload: responsePayload });
    callback(null, responsePayload);
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: LOGIN_USER_FAILURE,
      payload: errorPayload,
    });
    callback(errorPayload, null);
  }
};
// login user action end

// forgot Password send email start
export const forgotPasswordSendEmail =
  (credentials, callback) => async (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_SEND_EMAIL_REQUEST });

    try {
      const response = await ApiService.post(
        "/api/forgot-password",
        credentials
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: FORGOT_PASSWORD_SEND_EMAIL_SUCCESS,
        payload: responsePayload,
      });
      callback(null, response.data);
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: FORGOT_PASSWORD_SEND_EMAIL_FAILURE,
        error: errorPayload,
      });
      callback(errorPayload, null);
    }
  };
// forgot Password send end

// reset password start
export const resetPasswordEmail =
  (credentials, callback) => async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    try {
      const response = await ApiService.post(
        "/api/reset-password",
        credentials
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: responsePayload });
      callback(null, responsePayload);
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: RESET_PASSWORD_FAILURE,
        error: errorPayload,
      });
      callback(errorPayload, null);
    }
  };
// reset password end
//Add users Location By User id start
export const addUsersLocationByUserId =
  (formData, id, callback) => async (dispatch) => {
    dispatch({ type: ADD_USERS_LOCATION_REQUEST });

    try {
      const response = await ApiService.post(
        `/api/adduserslocationbyuserId/${id}`,
        formData
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: ADD_USERS_LOCATION_SUCCESS,
        payload: responsePayload,
      });
      callback(null, responsePayload);
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: ADD_USERS_LOCATION_FAILURE,
        error: errorPayload,
      });
      callback(errorPayload, null);
    }
  };

//Add users Location By User id end

export const UpdateuserslocationbyuserId =
  (credentials, id, callback) => async (dispatch) => {
    dispatch({ type: UPDATE_USERS_LOCATION_REQUEST });

    try {
      const response = await ApiService.post(
        `/api/updateuserslocationbyuserId/${id}`,
        credentials
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: UPDATE_USERS_LOCATION_SUCCESS,
        payload: responsePayload,
      });
      callback(null, responsePayload);
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: UPDATE_USERS_LOCATION_FAILURE,
        error: errorPayload,
      });
      callback(errorPayload, null);
    }
  };

export const addRegisterVoterId =
  (formDataVoter, id, callback) => async (dispatch) => {
    dispatch({ type: POST_REGISTRTVOTERID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/usersregistervoterId/${id}`,
        formDataVoter
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_REGISTRTVOTERID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        // message: error.message,
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_REGISTRTVOTERID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload);
      }
    }
  };

export const filterElectionResults =
  (electionTypeId, district_id, polling_division_id) => async (dispatch) => {
    dispatch({ type: GET_FILTERELECTIONRESULTS_REQUEST });
    try {
      const response = await ApiService.get(
        `/api/filterelectionresults/${electionTypeId}` +
        (district_id ? `?district_id=${district_id}` : "") +
        (polling_division_id
          ? `${district_id ? "&" : "?"
          }polling_division_id=${polling_division_id}`
          : "")
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: GET_FILTERELECTIONRESULTS_SUCCESS,
        payload: responsePayload,
      });
    } catch (error) {
      dispatch({
        type: GET_FILTERELECTIONRESULTS_FAILURE,
        payload: error,
      });
    }
  };

export const userDashboardData = (id) => async (dispatch) => {
  dispatch({ type: GET_USERDASHBOARD_REQUEST });
  try {
    const response = await ApiService.get(`/api/userdashboard/${id}`);

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_USERDASHBOARD_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_USERDASHBOARD_FAILURE,
      payload: errorPayload,
    });
  }
};

export const addPresidentialElectionResultsByDIdUid =
  (formData, Uid, Etid, callback) => async (dispatch) => {
    dispatch({ type: ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/addpresidentialelectionresults/${Uid}/${Etid}`,
        formData
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: ADD_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload);
      }
    }
  };

export const updatePresidentialElectionResultsByDIdUid =
  (formData, Uid, Etid, callback) => async (dispatch) => {
    dispatch({ type: UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/updatepresidentialelectionresults/${Uid}/${Etid}`,
        formData
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        status: error?.response?.status,
        data: error?.response?.data,
      };

      dispatch({
        type: UPDATE_PRESIDENTIAL_ELECTION_RESULTS_BY_DID_UID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };

export const getElectionUserIdElectionID = (Uid, Etid) => async (dispatch) => {
  dispatch({ type: GET_ELECTION_USERID_ELECTIONID_REQUEST });
  try {
    const response = await ApiService.get(`/api/election/${Uid}/${Etid}`);

    const responsePayload = {
      data: response.data,
    };
    dispatch({
      type: GET_ELECTION_USERID_ELECTIONID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      // message: error.message,
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_ELECTION_USERID_ELECTIONID_FAILURE,
      payload: errorPayload,
    });
  }
};

export const getWinnerCalculateVotes = () => async (dispatch) => {
  dispatch({ type: GET_WINNER_CALCULATE_VOTES_REQUEST });
  try {
    const response = await ApiService.get("/api/calculatevotes");

    const responsePayload = {
      data: response.data,
    };
    dispatch({
      type: GET_WINNER_CALCULATE_VOTES_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_WINNER_CALCULATE_VOTES_FAILURE,
      payload: errorPayload,
    });
  }
};

export const updateEditUserByUserId =
  (credentials, uid, callback) => async (dispatch) => {
    dispatch({ type: UPDATE_EDIT_USER_BY_USER_ID_REQUEST });

    try {
      const response = await ApiService.post(
        `/api/editUser/${uid}`,
        credentials
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: UPDATE_EDIT_USER_BY_USER_ID_SUCCESS,
        payload: responsePayload,
      });
      callback(null, responsePayload);
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: UPDATE_EDIT_USER_BY_USER_ID_FAILURE,
        error: errorPayload,
      });
      callback(errorPayload, null);
    }
  };

export const updateEmailUserId =
  (credentials, uid, callback) => async (dispatch) => {
    dispatch({ type: UPDATE_EMAIL_BY_USER_ID_REQUEST });

    try {
      const response = await ApiService.post(
        `/api/users/${uid}/update-email`,
        credentials
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: UPDATE_EMAIL_BY_USER_ID_SUCCESS,
        payload: responsePayload,
      });
      callback(null, responsePayload);
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: UPDATE_EMAIL_BY_USER_ID_FAILURE,
        error: errorPayload,
      });
      callback(errorPayload, null);
    }
  };

export const getallpresidentialsimulators = (Etid) => async (dispatch) => {
  dispatch({ type: GET_ALL_PRESIDENTIAL_SIMULATORS_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/getallpresidentialsimulators/${Etid}`
    );

    const responsePayload = {
      data: response.data,
    };
    dispatch({
      type: GET_ALL_PRESIDENTIAL_SIMULATORS_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_ALL_PRESIDENTIAL_SIMULATORS_FAILURE,
      payload: errorPayload,
    });
  }
};

export const allPresidentialElectionResults =
  (election_title_id, district_id) => async (dispatch) => {
    dispatch({ type: GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_REQUEST });
    try {
      let url = `/api/allpresidentialelectionresults`;

      if (election_title_id) {
        url += `?election_title_id=${election_title_id}`;

        if (district_id) {
          url += `&district_id=${district_id}`;
        }
      }
      const response = await ApiService.get(url);

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_SUCCESS,
        payload: responsePayload,
      });
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_FAILURE,
        payload: errorPayload,
      });
    }
  };
export const getAllCandidateResults = (candidate_id) => async (dispatch) => {
  dispatch({ type: GET_ALLCANDIDATERESULTS_BYID_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/allcandidatresults/${candidate_id}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_ALLCANDIDATERESULTS_BYID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_ALLCANDIDATERESULTS_BYID_FAILURE,
      payload: errorPayload,
    });
  }
};

export const addParliamentSimulatorByUidEid =
  (jsonData, Uid, Etid, callback) => async (dispatch) => {
    dispatch({ type: POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_REQUEST });
    try {
      const response = await ApiService.post(
        `/api/addparliamentsimulator/${Uid}/${Etid}`,
        jsonData
      );
      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        status: error.response.status,
        data: error.response.data,
      };
      dispatch({
        type: POST_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload);
      }
    }
  };

export const getParliamentSimulatorBySimulatorId = (sId) => async (dispatch) => {
  dispatch({ type: GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_REQUEST });
  try {
    const response = await ApiService.get(
      `/api/getparliamentsimulator/${sId}`
    );

    const responsePayload = {
      data: response.data,
      status: response.status,
    };
    dispatch({
      type: GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    const errorPayload = {
      status: error.response.status,
      data: error.response.data,
    };
    dispatch({
      type: GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_FAILURE,
      payload: errorPayload,
    });
  }
};

export const updateParliamentSimulatorByUidEid =
  (jsonData, Uid, Etid, callback) => async (dispatch) => {
    dispatch({
      type: POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_REQUEST,
    });
    try {
      const response = await ApiService.post(
        `/api/updateparliamentsimulator/${Uid}/${Etid}`,
        jsonData
      );

      const responsePayload = {
        data: response.data,
        status: response.status,
      };
      dispatch({
        type: POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_SUCCESS,
        payload: responsePayload,
      });
      if (callback) {
        callback(null, responsePayload);
      }
    } catch (error) {
      const errorPayload = {
        status: error?.response?.status,
        data: error?.response?.data,
      };

      dispatch({
        type: POST_UPDATE_PARLIAMENT_SIMULATOR_BY_USER_ID_ELECTION_TITLE_ID_FAILURE,
        payload: errorPayload,
      });
      if (callback) {
        callback(errorPayload, null);
      }
    }
  };




export const logoutUser = () => {
  localStorage.clear();
  return {
    type: LOGOUT_USER,
  };
};
