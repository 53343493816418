// import { getUserDetailsById, getWinnerCalculateVotes } from "./action";
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  FORGOT_PASSWORD_SEND_EMAIL_REQUEST,
  FORGOT_PASSWORD_SEND_EMAIL_SUCCESS,
  FORGOT_PASSWORD_SEND_EMAIL_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGOUT_USER,
  UPDATE_USERS_LOCATION_REQUEST,
  UPDATE_USERS_LOCATION_SUCCESS,
  UPDATE_USERS_LOCATION_FAILURE,
  GET_FILTERELECTIONRESULTS_REQUEST,
  GET_FILTERELECTIONRESULTS_SUCCESS,
  GET_FILTERELECTIONRESULTS_FAILURE,
  GET_USERDASHBOARD_REQUEST,
  GET_USERDASHBOARD_SUCCESS,
  GET_USERDASHBOARD_FAILURE,
  GET_ELECTION_USERID_ELECTIONID_REQUEST,
  GET_ELECTION_USERID_ELECTIONID_SUCCESS,
  GET_ELECTION_USERID_ELECTIONID_FAILURE,
  // GET_USER_DETAILS_BY_ID_REQUEST,
  // GET_USER_DETAILS_BY_ID_SUCCESS,
  // GET_USER_DETAILS_BY_ID_FAILURE,
  GET_ALL_PRESIDENTIAL_SIMULATORS_REQUEST,
  GET_ALL_PRESIDENTIAL_SIMULATORS_SUCCESS,
  GET_ALL_PRESIDENTIAL_SIMULATORS_FAILURE,
  GET_WINNER_CALCULATE_VOTES_REQUEST,
  GET_WINNER_CALCULATE_VOTES_SUCCESS,
  GET_WINNER_CALCULATE_VOTES_FAILURE,
  GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_REQUEST,
  GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_SUCCESS,
  GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_FAILURE,
  GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_REQUEST,
  GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_SUCCESS,
  GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_FAILURE,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: null,
  indexCandidateData: [],
  userdashboardData: [],
  getElectionUiEiData: [],
  getUserDetailsByIdData: [],
  getAllPresedentialSimulatorsData: [],
  getWinnerCalculateVotes: [],
  getAllPresedentialElectionResultData: [],
  getParliamentSimulatorBySidData: []
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REGISTER_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case REGISTER_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESEND_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESEND_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FORGOT_PASSWORD_SEND_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FORGOT_PASSWORD_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FORGOT_PASSWORD_SEND_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_USERS_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_USERS_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_USERS_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_FILTERELECTIONRESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_FILTERELECTIONRESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        indexCandidateData: action.payload,
        error: null,
      };
    case GET_FILTERELECTIONRESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USERDASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_USERDASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        userdashboardData: action.payload,
        error: null,
      };
    case GET_USERDASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ELECTION_USERID_ELECTIONID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ELECTION_USERID_ELECTIONID_SUCCESS:
      return {
        ...state,
        loading: false,
        getElectionUiEiData: action.payload,
        error: null,
      };
    case GET_ELECTION_USERID_ELECTIONID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_WINNER_CALCULATE_VOTES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_WINNER_CALCULATE_VOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        getWinnerCalculateVotes: action.payload,
        error: null,
      };
    case GET_WINNER_CALCULATE_VOTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ALL_PRESIDENTIAL_SIMULATORS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_PRESIDENTIAL_SIMULATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPresedentialSimulatorsData: action.payload,
        error: null,
      };
    case GET_ALL_PRESIDENTIAL_SIMULATORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPresedentialElectionResultData: action.payload,
        error: null,
      };
    case GET_ALLPRESIDENTIALELECTIONRESULTS_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getParliamentSimulatorBySidData: action.payload,
        error: null,
      };
    case GET_PARLIAMENT_SIMULATOR_BY_SIMULATOR_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOGOUT_USER:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default UserReducer;
