import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaLocationArrow } from "react-icons/fa6";
import { Col, Container, Row } from "react-bootstrap";
import { indexParties } from "../../../redux/adminone/action";
// import { cApiUrlPrefix } from "../../../config/envConfig";
// import disImg from "../../../assets/images/sreelamkaImg.png";
import Loader from "../../loaders/loader";
import SolidBlueBtn from "../../buttons/solidBlueBtn";
import { HiArrowNarrowRight } from "react-icons/hi";
// import CardSeven from "../../cards/cardSeven";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const ChoosePartyCandidate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  //   const [activeCardId, setActiveCardId] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserData(parsedUser);
      //   setUsername(parsedUser.name);
      //   setFormData({ voter_id: parsedUser.voter_id || "" });

      //   if (Array.isArray(indexAllManageElectionData)) {
      //     const filteredElections = getFilteredElections(
      //       indexAllManageElectionData
      //     );
      //     const newOptions = filteredElections.map((election) => ({
      //       value: election.election_type_id,
      //       label: election.election_title,
      //       election_start_date: election.election_start_date,
      //       election_end_date: election.election_end_date,
      //       poll_start_date: election.poll_start_date,
      //       poll_end_date: election.poll_end_date,
      //     }));
      //     setOptions(newOptions);

      //     if (!selectedFilter) {
      //       if (location.state?.title) {
      //         const selectedOption = newOptions.find(
      //           (option) => option.label === location.state.title
      //         );
      //         if (selectedOption) {
      //           setSelectedFilter(selectedOption);
      //           setElectionDates({
      //             election_start_date: selectedOption.election_start_date,
      //             election_end_date: selectedOption.election_end_date,
      //             poll_start_date: selectedOption.poll_start_date,
      //             poll_end_date: selectedOption.poll_end_date,
      //           });
      //         }
      //       } else if (location.state?.electionTitleId) {
      //         const selectedOption = newOptions.find(
      //           (option) => option.value === location.state.electionTitleId
      //         );
      //         if (selectedOption) {
      //           setSelectedFilter(selectedOption);
      //           setElectionDates({
      //             election_start_date: selectedOption.election_start_date,
      //             election_end_date: selectedOption.election_end_date,
      //             poll_start_date: selectedOption.poll_start_date,
      //             poll_end_date: selectedOption.poll_end_date,
      //           });
      //         }
      //       } else if (newOptions.length > 0) {
      //         setSelectedFilter(newOptions[0]);
      //         setElectionDates({
      //           election_start_date: newOptions[0].election_start_date,
      //           election_end_date: newOptions[0].election_end_date,
      //           poll_start_date: newOptions[0].poll_start_date,
      //           poll_end_date: newOptions[0].poll_end_date,
      //         });
      //       }
      //     }
      //   }
    }
  }, []);
  useEffect(() => {
    dispatch(indexParties());
  }, [dispatch]);
  //   const indexPartiesData = useSelector(
  //     (state) => state.adminone.indexPartiesData?.data
  //   );
  const loading = useSelector((state) => state.adminone.loading);
  //   const data =
  //     indexPartiesData?.map((party) => ({
  //       id: party.id,
  //       name: party.party_name,
  //       image: party.logo === "0" ? disImg : cApiUrlPrefix + party.logo,
  //     })) || [];

  //   const handleClick = (id, party_name) => {
  //     setActiveCardId(id);
  //     const { electionTypeId, title } = location.state || {};
  //     navigate("/select-polling-division", {
  //       state: { electionTypeId, title, id, party_name },
  //     });
  //   };
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   const activeId = user?.id;
  //   const props = {
  //     headings: {
  //       heading1: "DISTRICTS",
  //     },

  //     children: {
  //       children1: "Enter into O'Democracy Dashboard",
  //     },
  //     data,
  //     handleClick,
  //     activeId,
  //   };
  return (
    <div className="choose-party-parliament">
      <div className="d-lg-flex d-block justify-content-between align-items-center first-div">
        <div className="hero-h5">
          <h5>
            Choose party candidate for{" "}
            {location.state?.title || "Election Title"}
            <span className="ps-2">(Poll)</span>
          </h5>
          <p>
            Sri Lanka, {userData?.district_name},{" "}
            {userData?.polling_division_name}{" "}
            <FaLocationArrow
              className="location-i"
              onClick={() => {
                navigate(`/select-election`);
              }}
            />
          </p>
        </div>
      </div>
      <Container fluid className="layOut-one-container">
        <div className="border-container">
          <Row className="layOut-one-row" md={5}>
            {/* {props.data.map((data) => (
              <Col key={data.id} className="layOut-one-col">
                <CardSeven
                  // className={`card-seven ${
                  //   data.id === props.activeId ? "active" : ""
                  // }`}
                  className={`card-seven ${
                    data.id === activeCardId ? "active-card" : ""
                  }`}
                  image={data.image}
                  title={data.name}
                  onClick={() => props.handleClick(data.id, data.name)}
                  isActive={data.id === activeCardId}
                />
              </Col>
            ))} */}
            <Col>
              <Row>
                <Col></Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
          <Row className="button-wrapper">
            <SolidBlueBtn
              type="submit"
              className="solidBlueBtn"
              onClick={() => navigate("/parliamentresult")}
            >
              Submit your vote
              <span className="arrowBtn">
                <HiArrowNarrowRight />
              </span>
            </SolidBlueBtn>
          </Row>
        </div>
      </Container>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </div>
  );
};

export default ChoosePartyCandidate;
