import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Cselect from "../../../components/select/cselect";
import ElectionCard from "../../../components/user/election/electionCard";
import { formatDate } from "../../../utilities/common";
import OrangeSolidBtn from "../../../components/buttons/orangeSolidBtn";

const ElectionsMain = (props) => {
  const {
    headings,
    dateHeading,
    cstomStyle,
    isSearchable,
    options,
    className,
    handleFilterChange,
    selectedFilter,
    data = [],
    children,
    handleClick,
    icons,
    images,
    paras,
    handleClick1,
    handleClick2,
  } = props;

  return (
    <main className="main-election">
      <Container fluid className="elections-container">
        <Row className="elections-row">
          <Col md={3} className="ps-0 elections-col">
            <h2 className="elections-heading">{headings.heading1}</h2>
          </Col>
          <Col md={{ span: 3, offset: 4 }} className="elections-col">
            <OrangeSolidBtn
              children={children.children3}
              onClick={handleClick2}
            />
          </Col>
          <Col md={2} className="elections-col">
            <Cselect
              id="districtSelect"
              options={options}
              className={className.cselectpageSize2}
              styles={cstomStyle}
              onChange={handleFilterChange}
              value={selectedFilter}
            />
          </Col>
        </Row>
        <Row className="elections-row" xs={1}>
          {data.map((election) => (
            <Col key={election.id} className="elections-col">
              <ElectionCard
                headings={dateHeading}
                paras={paras}
                images={images}
                icons={icons}
                title={election.election_title}
                electionStartDate={formatDate(election.election_start_date)}
                electionEndDate={formatDate(election.election_end_date)}
                pollStartDate={formatDate(election.poll_start_date)}
                pollEndDate={formatDate(election.poll_end_date)}
                type={election.type_of_election_name}
                children={children}
                pe={election.poll_end_date}
                onClick={() =>
                  handleClick(
                    election.election_type_id,
                    election.election_title
                  )
                }
                onClickExpired={() =>
                  handleClick1(
                    election.election_type_id,
                    election.election_title
                  )
                }
              />
            </Col>
          ))}
        </Row>
      </Container>
    </main>
  );
};

export default ElectionsMain;
