import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Cselect from "../../select/cselect";
import { Link } from "react-router-dom";
import NewProgressBar from "../../newProgressBar";
import { ImArrowUpRight2 } from "react-icons/im";

const HeroResults = (props) => {
  const {
    options,
    selectChange,
    value,
    cstomStyle,
    contentTabs,
    // percentage = 88,
    // size = 200,
    // strokeWidth = 10,
    // strokeColor = "orange",
  } = props;

  // const validPercentage =
  //   !isNaN(percentage) && percentage !== null ? percentage : 0;

  // const radius = (size - strokeWidth) / 2;
  // const circumference = 2 * Math.PI * radius;
  // const offset = circumference - (validPercentage / 100) * circumference;
  // console.log("offset", offset);
  // console.log("circumference", circumference);

  return (
    <section className="hero-results">
      <Container fluid className="px-0">
        <Row md={3}>
          <Col className="header">
            <h2>Parliament Election Results</h2>
            <Cselect
              options={options}
              className="cselectpageSize1"
              styles={cstomStyle}
              onChange={selectChange}
              value={value}
              placeholder="Parliament Results"
              name="parliamentresults"
            />
          </Col>
          <Col className="align-middle">
            <div className="provincial-details">
              {contentTabs.provincialDetailsData.map((item, index) => (
                <div className="d-flex right-border" key={index}>
                  <span className={`sqr-box${index}`}></span>
                  <div className="registered-e-div">
                    <h6 className="provincial-vote">{item.value}</h6>
                    <p className="registered-electors mt-1">{item.label}</p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
          <Col className="vPercentage">
            <aside>
              <NewProgressBar />
            </aside>
            <aside>
              <p>Total votes percentage</p>
              <Link to={"/"}>
                View Detailed Result
                <span className="arrow">
                  <ImArrowUpRight2 />
                </span>
              </Link>
            </aside>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroResults;
