import React, { useState } from "react";
import { MdOutlineHowToVote } from "react-icons/md";
import { Link } from "react-router-dom";
import { LiaGreaterThanSolid } from "react-icons/lia";
import { Container, Button, Row, Col } from "react-bootstrap";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

function VoteSimulator(props) {
  const { uniqueElections } = props;
  const [showVoteSimulator, setShowVoteSimulator] = useState(true);

 

  const electionMap = uniqueElections.reduce((acc, election) => {
    acc[election.type_of_election_name] = election.election_type_id;
    return acc;
  }, {});

  


  const handleClick = () => {
    setShowVoteSimulator(false);
  };

  const electionTypes = [
    "Presidential Election",
    "Parlament Election",
    "Provincial Council Elections",
    "Local Authorities Elections",
    "Referendum",
  ];

  if (!showVoteSimulator) {
    return null;
  }

  return (
    <div className="voteWrapper">
      <div className="voteSimulator">
        <Container fluid>
          <Row md={2} xs={1} className="voteSimulator-row">
            <Col className="pe-md-5">
              <div className="electionType d-flex align-items-center">
                <MdOutlineHowToVote className="vote-icon" />
                <h6 className="h-electionType ms-3">Election Type</h6>
              </div>
              <div className="my-4">
                {electionTypes.map((electionType, index) => {
                  const electionTypeId = electionMap[electionType];
                  return (
                    <Link
                      key={index}
                      to={`/select-election`} state={{ electionTypeId }}
                      className="text-decoration-none h-votesimulator-body"
                    >
                      <LiaGreaterThanSolid />{" "}
                      <span className="ms-3">{electionType}</span>
                    </Link>
                  );
                })}
              </div>
            </Col>
            <Col className="ps-md-5">
              <div className="electionType-2 d-flex align-items-center">
                <MdOutlineHowToVote className="vote-icon-2" />
                <h6 className="h-electionType ms-3">Results</h6>
              </div>
              <div className="my-4">
                {electionTypes.map((electionType, index) => (
                  <Link
                    key={index}
                    to={`/results/${electionType
                      .toLowerCase()
                      .replace(/\s+/g, "-")}`}
                    className="text-decoration-none h-votesimulator-body"
                  >
                    <LiaGreaterThanSolid />{" "}
                    <span className="ms-3">{electionType}</span>
                  </Link>
                ))}
              </div>
            </Col>
          </Row>
          <Link to="/simulator" className="text-decoration-none">
            <Button
              onClick={handleClick}
              className="d-flex justify-content-between align-items-center w-100 vote-simulator-btn"
            >
              <aside className="d-flex align-items-center">
                <MdOutlineHowToVote className="vote-icon-3" />
                <span className="ps-2">Vote Simulator</span>
              </aside>
              <aside>
                <MdKeyboardDoubleArrowRight className="swipe-arrow" />
              </aside>
            </Button>
          </Link>
        </Container>
      </div>
    </div>
  );
}

export default VoteSimulator;
