import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
// import OrangeBorderBtn from "../../../components/buttons/orangeBorderBtn";
import { cApiUrlPrefix } from "../../../config/envConfig";
import { GoArrowUpRight, GoTrophy } from "react-icons/go";
import { FaLocationArrow } from "react-icons/fa";
import ManageTable from "../../../components/table/manageTable";
import BorderBlueBtn from "../../../components/buttons/borderBlueBtn";
import { useNavigate } from "react-router-dom";

const WinnerMain = (props) => {
    const navigate = useNavigate();
    return (
        <main className="winner-main">
            <Container fluid className="winner-container">
                <Row className="winner-row">
                    <Col className="winner-col">
                        <h2 className="winner-heading">{props.title} <span className="">(Poll)</span></h2>
                        <p className="winner-location">
                            {props.locations.location1} <FaLocationArrow className="location-i" onClick={() => { navigate(`/select-election`); }} />
                        </p>
                    </Col>
                </Row>
                <Row className="winner-row">
                    <Col className="winner-col" md={1}>
                        <h2 className="winner-heading">{props.headings.heading1}</h2>
                    </Col>
                    {/* <Col className="winner-col" md={{ span: 2, offset: 7 }}>
                        <OrangeBorderBtn children={props.Childrens.children1} />
                    </Col>
                    <Col className="winner-col" md={2}>
                        <OrangeBorderBtn children={props.Childrens.children2} />
                    </Col> */}
                </Row>
                <Row className="winner-row"  >
                    <Col className="winner-col">
                        <Card className="winner-card">
                            <div className="winner-blok-des">
                                <div className="winner-blok-design">
                                    <div className="winner-imgBlk">
                                        <Card.Img
                                            src={
                                                cApiUrlPrefix +
                                                props.getWinnerCalculateVote?.winner.candidate_image
                                            }
                                            className="winner-img"
                                        />
                                    </div>
                                </div>
                            </div>

                            <Card.Body>
                                <h3 className="winner-votes">
                                    {props.getWinnerCalculateVote?.winner.votes}
                                    <span className="winner-votesTxt">votes</span>
                                </h3>
                                <p className="winner-name">
                                    {props.getWinnerCalculateVote?.winner.candidate_name}{" "}
                                    <span>
                                        <GoArrowUpRight className="winner-arw" />
                                    </span>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="winner-col">
                        <Card className="winner-card">
                            <div className="winner-blok-des">
                                <div className="winner-blok-design">
                                    <div className="winner-imgBlk">
                                        <Card.Img
                                            src={
                                                cApiUrlPrefix +
                                                props.getWinnerCalculateVote?.winner.party_logo
                                            }
                                            className="winner-img"
                                        />
                                    </div>
                                </div>
                            </div>

                            <Card.Body>
                                <h3 className="winner-votes">
                                    {props.getWinnerCalculateVote?.winner.party_name}
                                </h3>
                                <p className="winner-name">
                                    {props.getWinnerCalculateVote?.winner.party_name}{" "}
                                    <span>
                                        <GoArrowUpRight className="winner-arw" />
                                    </span>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="winner-col">
                        <Card className="winner-card">
                            <div className="winner-blok-des">
                                <div className="winner-blok-design">
                                    <div className="winner-imgBlk">
                                        <GoTrophy className="trophy" />
                                    </div>
                                </div>
                            </div>

                            <Card.Body>
                                <h3 className="winner-votes">
                                    1st Proprity
                                </h3>
                                <p className="winner-name">
                                    Win with highest votes
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div className="winner-candiate">
                <div className="winner-candiate-box">
                    <div className="provincial-details mt-4">
                        {props.provincialDetailsData.map((item, index) => (
                            <div className="d-flex right-border" key={index}>
                                <span className={`sqr-box${index}`}></span>
                                <div className="registered-e-div">
                                    <h6 className="provincial-vote">{item.value}</h6>
                                    <p className="registered-electors mt-1">{item.label}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <ManageTable data={props.data} labels={props.labels} />
                </div>
            </div>
            <div className="winner-btn-bx">
                <BorderBlueBtn children={props.Childrens.children3} />
            </div>



        </main>
    );
};

export default WinnerMain;
