import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import heroImg2 from "../../../assets/images/voterImg.png";
import ElectionsMain from "./electionsMain";
import {
  indexGetAllElectionDetail,
  indexGetAllManageElections,
} from "../../../redux/adminone/action";
import { SlCalender } from "react-icons/sl";
import { useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardDoubleArrowRight, MdOutlineHowToVote } from "react-icons/md";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const ElectionsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { electionTypeId } = location.state || {};

  // console.log("electionTypeId", electionTypeId);

  const [selectedFilter, setSelectedFilter] = useState({
    value: "all",
    label: "All",
  });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    dispatch(indexGetAllManageElections());
    dispatch(indexGetAllElectionDetail());
  }, [dispatch]);

  const loading = useSelector((state) => state.adminone.loading);
  const indexAllManageElectionData = useSelector(
    (state) => state.adminone.indexAllManageElectionData?.data
  );
  const indexAllElectionDetailsData = useSelector(
    (state) => state.adminone.indexAllElectionDetailsData?.data
  );

  useEffect(() => {
    if (
      Array.isArray(indexAllElectionDetailsData) &&
      indexAllElectionDetailsData.length > 0
    ) {
      const newOptions = [
        { value: "all", label: "All" },
        ...indexAllElectionDetailsData.map((election) => ({
          value: election.id,
          label: election.type_of_election_name,
        })),
      ];

      setOptions(newOptions);

      const initialFilter = newOptions.find(
        (option) => option.value === electionTypeId
      ) || { value: "all", label: "All" };

      setSelectedFilter(initialFilter);
    }
  }, [indexAllElectionDetailsData, electionTypeId]);

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
  };

  const data =
    selectedFilter?.value === "all"
      ? indexAllManageElectionData
      : indexAllManageElectionData?.filter(
          (item) => item.election_type_id === selectedFilter?.value
        );

  const filteredElectionData = indexAllManageElectionData?.filter(
    (item) => item.election_type_id === 1 || item.election_type_id === 2
  );

  const handleClick = (electionTypeId, title) => {
    // navigate("/select-district", { state: { electionTypeId, title } });

    const selectedElection = filteredElectionData?.find(
      (election) => election.election_type_id === electionTypeId
    );
    console.log("selectedElection", selectedElection);

    if (!selectedElection) {
      console.error("Election type not found.");
      return;
    }

    const electionTitleId = selectedElection.id;

    if (selectedElection.election_type_id === 2) {
      navigate("/parliament-hero-section", {
        state: {
          electionTypeId,
          electionTitleId,
          title,
          poll_start_date: selectedElection.poll_start_date,
          poll_end_date: selectedElection.poll_end_date,
          election_start_date: selectedElection.election_start_date,
          election_end_date: selectedElection.election_end_date,
        },
      });
    } else if (selectedElection.election_type_id === 1) {
      navigate(`/select-district`, {
        state: { electionTypeId, title, electionTitleId },
      });
    } else {
      console.error("Election type is not recognized.");
    }
  };

  console.log("Filtered Election Data:", filteredElectionData);

  const handleClick1 = (electionTypeId, title) => {
    const selectedElection = filteredElectionData?.find(
      (election) => election.election_type_id === electionTypeId
    );

    if (!selectedElection) {
      console.error("Election type not found.");
      return;
    }

    const electionTitleId = selectedElection.id;

    // console.log("electionTitleId:", electionTitleId);

    if (selectedElection.election_type_id === 2) {
      navigate("/parliament-election-poll-results", {
        state: {
          electionTypeId,
          electionTitleId,
          title,
          poll_start_date: selectedElection.poll_start_date,
          poll_end_date: selectedElection.poll_end_date,
          election_start_date: selectedElection.election_start_date,
          election_end_date: selectedElection.election_end_date,
        },
      });
    } else if (selectedElection.election_type_id === 1) {
      navigate(`/winner?title=${title}`, {
        state: { electionTypeId, title, electionTitleId },
      });
    } else {
      console.error("Election type is not recognized.");
    }
  };

  const handleClick2 = () => {
    navigate("/simulator");
  };

  const props = {
    headings: {
      heading1: "ELECTIONS",
    },
    dateHeading: {
      heading1: "Election start date",
      heading2: "Election end date",
      heading3: "Poll start date",
      heading4: "Poll end date",
    },
    className: {
      cselectpageSize2: "cselectpageSize2",
    },
    children: {
      children1: "Check Live Details",
      children2: "Click Here To See Exit Poll Result",
      children3: (
        <>
          <MdOutlineHowToVote className="vote-icon-3" />{" "}
          <span>Vote Simulator</span>{" "}
          <MdKeyboardDoubleArrowRight className="swipe-arrow" />
        </>
      ),
    },
    titles: {},
    paras: {
      para1:
        " O'Democracy is conducting a poll for the upcoming presidential election. Submit your vote and see the result.",
    },
    images: {
      image1: { heroImg2 },
    },
    icons: {
      icon1: <SlCalender />,
    },
    cstomStyle,
    options,
    handleFilterChange,
    selectedFilter,
    value: selectedFilter,
    handleClick,
    handleClick1,
    handleClick2,
    data,
  };

  return (
    <>
      <ElectionsMain {...props} />
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </>
  );
};

export default ElectionsPage;
