import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { OverlayTrigger, Table } from "react-bootstrap";
import ParlamentExitPollResultMain from "./parlamentExitPollResultMain";
import { calculateVotingPercentage } from "../../../utilities/common";
import IImage from "../../../components/image/iImage";
import OffCanvas from "../../../components/offCanvas/offCanvas";
import ManageTable from "../../../components/table/manageTable";
const ParlamentExitPollResultPage = () => {
    const location = useLocation();
    const { updateData1, selectedOption } = location.state || {};

    const user = JSON.parse(localStorage.getItem("user"));
    const [filterData, setFilterData] = useState(null);
    console.log("filterData", filterData);

    const [seatsTable, setSeatTable] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const resultJSON = {
        simulators: {},
    };
    let partyData = {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tableData = {};

    if (updateData1 && updateData1.simulators) {
        Object.keys(updateData1.simulators).forEach((districtKey) => {
            const district = updateData1.simulators[districtKey];
            resultJSON.simulators[districtKey] = {
                district_name: district.district_name,
                simulators: {},
            };

            const allVotes = [];

            Object.keys(district.simulators).forEach((partyKey) => {
                const party = district.simulators[partyKey];
                const partySimData = (resultJSON.simulators[districtKey].simulators[
                    partyKey
                ] = {
                    party_name: party.party_name,
                    party_logo: party.party_logo,
                    votes: [],
                });

                for (let i = 0; i < district.district_total_seats; i++) {
                    const voteCount =
                        i === 0
                            ? party.party_total_votes
                            : Math.abs(party.party_total_votes / (i + 1));
                    partySimData.votes.push(voteCount);
                    allVotes.push(voteCount);
                }

                if (!tableData[partyKey]) {
                    tableData[partyKey] = {
                        party_name: party.party_name,
                        all_district_party_seats: 0,
                    };
                }
            });

            const topTenVotes = allVotes
                .sort((a, b) => b - a)
                .slice(0, district.district_total_seats);

            Object.keys(district.simulators).forEach((partyKey) => {
                const partySimData =
                    resultJSON.simulators[districtKey].simulators[partyKey];
                const filteredVotes = partySimData.votes.filter((vote) =>
                    topTenVotes.includes(vote)
                );

                partySimData.votes = filteredVotes;
                const seatCount = filteredVotes.length;
                tableData[partyKey][districtKey] = {
                    district_name: district.district_name,
                    party_seats: seatCount,
                    party_district_vote: "",
                };
                tableData[partyKey].all_district_party_seats += seatCount;
            });
        });

        Object.keys(updateData1.simulators).forEach((districtId) => {
            const district = updateData1.simulators[districtId];

            Object.keys(district.simulators).forEach((partyId) => {
                const party = district.simulators[partyId];
                const partySeats =
                    resultJSON.simulators[districtId].simulators[partyId].votes.length;

                if (!partyData[partyId]) {
                    partyData[partyId] = {
                        party_name: party.party_name,
                        party_votes: 0,
                        party_seats: 0,
                        party_logo: party.party_logo,
                        national_seats: 0,
                        simulator: [],
                    };
                }

                partyData[partyId].party_votes += party.party_total_votes;
                partyData[partyId].party_seats += partySeats;

                const candidates = party.simulators.map((candidate) => ({
                    candidate_id: candidate.candidate_id,
                    candidate_name: candidate.candidate_name,
                    candidate_image: candidate.candidate_image,
                    candidate_vote: candidate.candidate_vote,
                    candidate_seats: candidate.candidate_seats,
                    district_id: districtId,
                    district_name: district.district_name,
                }));

                const sortedCandidates = candidates.sort(
                    (a, b) => b.candidate_vote - a.candidate_vote
                );

                partyData[partyId].simulator.push(...sortedCandidates);

                if (partySeats > 0) {
                    sortedCandidates.forEach((candidate, index) => {
                        candidate.candidate_seats = index < partySeats ? 1 : "";
                    });
                }

                if (updateData1.all_district_total_votes) {
                    partyData[partyId].national_seats = Math.round(
                        (((100 * partyData[partyId].party_votes) /
                            updateData1.all_district_total_votes) *
                            29) /
                        100
                    );
                }
            });
        });
    }

    useEffect(() => {
        if (!tableData || Object.keys(tableData).length === 0) {
            return;
        }
        const districtMap = {};

        Object.entries(tableData).forEach(([partyId, partyData]) => {
            Object.entries(partyData).forEach(([districtId, districtInfo]) => {
                if (!["party_name", "all_district_party_seats"].includes(districtId)) {
                    if (!districtMap[districtId]) {
                        districtMap[districtId] = districtInfo.district_name;
                    }
                }
            });
        });

        const districtIds = Object.keys(districtMap);

        const tableContent = (
            <Table responsive className="manageTable1">
                <thead>
                    <tr>
                        <th className="sticky-column-left">Parties</th>
                        {districtIds.map((districtId) => (
                            <th key={districtId}>{districtMap[districtId]}</th>
                        ))}
                        <th className="sticky-column-right">Total Seats</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(tableData).map(([partyId, partyData]) => (
                        <tr key={partyId}>
                            <td className="sticky-column-left">{partyData.party_name}</td>
                            {districtIds.map((districtId) => {
                                const seats = partyData[districtId]?.party_seats || 0;
                                return (
                                    <td key={districtId}>
                                        <div
                                            className="clickCell"
                                            onClick={() => onCellClick(partyId, districtId)}
                                        >
                                            {seats}
                                        </div>
                                    </td>
                                );
                            })}
                            <td className="sticky-column-right">
                                <div
                                    className="clickCell"
                                    onClick={() => onCellClick(partyId, null)}
                                >
                                    {partyData.all_district_party_seats}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );

        if (JSON.stringify(seatsTable) !== JSON.stringify(tableContent)) {
            setSeatTable(tableContent);
        }
    }, [tableData, seatsTable, setSeatTable]);

    const provincialDetailsData = [
        { label: "Registered Electors", value: updateData1.totalRegisteredVoters },
        { label: "Total Votes", value: updateData1.all_district_total_votes },
        {
            label: "Total Voting Percentage",
            value: calculateVotingPercentage(
                updateData1.all_district_total_votes,
                updateData1.totalRegisteredVoters
            ),
        },
        { label: "Total Districts", value: updateData1.totalDistrictsCount },
        {
            label: "Total Polling Divisions",
            value: updateData1.totalPollingDivisions,
        },
        {
            label: "Total Seats",
            value: updateData1.total_seats + 29,
        },
    ];

    const labels = [
        "Logo",
        "Name",
        "Votes",
        "Seats Percentage",
        // "National Seats",
        "Total Seats",
    ];

    const data = Object.entries(partyData).map(([, party]) => ({
        Logo: (
            <figure className="mpLogo">
                <IImage src={party.party_logo} className="mpLogoImg" />
            </figure>
        ),
        Name: party.party_name,
        Votes: party.party_votes,
        "Seats Percentage":
            Math.round(
                ((party.party_seats + party.national_seats) * 100) /
                (updateData1.total_seats + 29)
            ) + "%",
        // "National Seats": party.national_seats,
        "Total Seats": party.party_seats + party.national_seats,
    }));
    const onCellClick = (partyId, districtId) => {
        const selectedParty = partyData[partyId];

        if (!selectedParty) {
            return;
        }
        const filteredCandidates = selectedParty.simulator.filter(
            (candidate) => !districtId || candidate.district_id === districtId
        );
        const filteredPartyData = {
            party_name: selectedParty.party_name,
            party_votes: selectedParty.party_votes,
            party_seats: selectedParty.party_seats,
            national_seats: selectedParty.national_seats,
            party_logo: selectedParty.party_logo,
            candidates: filteredCandidates,
        };

        setFilterData(filteredPartyData);

        handleShow();
    };
    const labels1 = [
        "Candidate Image",
        "Candidate Name",
        "District Name",
        "Votes",
        "Seat",
    ];

    const data1 =
        filterData?.candidates?.map((candidate) => ({
            "Candidate Image": (
                <figure className="mpLogo">
                    <IImage src={candidate.candidate_image} className="mpLogoImg" />
                </figure>
            ),
            "Candidate Name": candidate.candidate_name,
            "District Name": candidate.district_name,
            Votes: candidate.candidate_vote,
            Seat: candidate.candidate_seats,
        })) || [];

    const extractPercentage = (percentageString) => {
        return parseInt(percentageString, 10);
    };

    let leadingParty = data.reduce((prev, current) => {
        const prevPercentage = extractPercentage(prev["Seats Percentage"]);
        const currentPercentage = extractPercentage(current["Seats Percentage"]);

        return currentPercentage > prevPercentage ? current : prev;
    });

    const getRandomColor = () => {
        const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
        return randomColor;
    };

    const parliamentArray = Object.entries(partyData).map(([, party]) => [
        party.party_name,
        party.party_seats + party.national_seats,
        getRandomColor(),
    ]);

    const OffCanvasHeader = (
        <div className="off-canvas-header">
            <figure className="mpLogo1">
                <IImage src={filterData?.party_logo} className="mpLogoImg" />
            </figure>
            <div className="flex-grow-1 me-auto">
                <h2 className="off-canvas-ptitle">{filterData?.party_name}</h2>
            </div>
            <div className="d-flex right-border">
                <span className="sqr-box0"></span>
                <div className="registered-e-div">
                    <h6 className="provincial-vote">{filterData?.party_votes}</h6>
                    <p className="registered-electors">Party Votes</p>
                </div>
            </div>
            <div className="d-flex right-border">
                <span className="sqr-box1"></span>
                <div className="registered-e-div">
                    <h6 className="provincial-vote">{filterData?.party_seats}</h6>
                    <p className="registered-electors">Party Seats</p>
                </div>
            </div>
            <div className="d-flex right-border">
                <span className="sqr-box2"></span>
                <div className="registered-e-div">
                    <h6 className="provincial-vote">{filterData?.national_seats}</h6>
                    <p className="registered-electors">National Seats</p>
                </div>
            </div>
        </div>
    );

    const options = {
        chart: {
            type: "item",
        },
        title: {
            text: "Parliament of seats",
            style: {
                fontSize: "1.4rem",
            },
        },
        // subtitle: {
        //     text: 'Bundestag election 2021. Source: ' +
        //         '<a href="https://www.bundeswahlleiter.de/en/bundeswahlleiter.html" target="_blank">Bundeswahlleiter</a>',
        // },
        legend: {
            labelFormat: '{name} <span style="opacity: 0.8">{y}</span>',
            itemStyle: {
                fontSize: "1.4rem",
            },
        },
        series: [
            {
                name: "Representatives",
                keys: ["name", "y", "color"],
                data: parliamentArray,
                dataLabels: {
                    enabled: false,
                    format: "{point.label}",
                },
                center: ["50%", "88%"],
                size: "170%",
                startAngle: -100,
                endAngle: 100,
            },
        ],
        tooltip: {
            style: {
                fontSize: "1.4rem",
            },

            colorByPoint: true,
            // formatter: function () {
            //     const pointIndex = this.point.index;
            //     const votes = voteArray[pointIndex];
            //     // const candidate = cNameArray[pointIndex];
            //     const partyName = partyArray[pointIndex];
            //     return `<b>${this.x}</b><br/>Votes: ${votes}<br/>Party: ${partyName}`;
            // },
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"],
                },
            },
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 600,
                    },
                    chartOptions: {
                        series: [
                            {
                                dataLabels: {
                                    distance: -30,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    };

    const props = {
        selectedOption,
        locationDistrict: user.district_name,
        locationPollingDivision: user.polling_division_name,
        provincialDetailsData,
        leadingParty,
        tableData,
        labels,
        data,
        options,
        components: {
            component1: seatsTable,
        },
        OffCanvasHeader: OffCanvasHeader,
        OffCanvasBody: (
            <ManageTable labels={labels1} data={data1} className="manageTable1" />
        ),
    };

    return (
        <>
            <ParlamentExitPollResultMain {...props} />

            <OffCanvas
                OffCanvasHeader={props.OffCanvasHeader}
                OffCanvasBody={props.OffCanvasBody}
                show={show}
                handleClose={handleClose}
                placement="end"
            />
        </>
    );
};

export default ParlamentExitPollResultPage;
