import {
  GET_ALLCANDIDATERESULTS_BYID_FAILURE,
  GET_ALLCANDIDATERESULTS_BYID_REQUEST,
  GET_ALLCANDIDATERESULTS_BYID_SUCCESS,
} from "../user/actionTypes";
import {
  GET_DASHBOARD_ADMIN_REQUEST,
  GET_DASHBOARD_ADMIN_SUCCESS,
  GET_DASHBOARD_ADMIN_FAILURE,
  GET_SECURITY_LEVEL_DETAILS_BY_ID_REQUEST,
  GET_SECURITY_LEVEL_DETAILS_BY_ID_SUCCESS,
  GET_SECURITY_LEVEL_DETAILS_BY_ID_FAILURE,
  SECURITIES_REQUEST,
  SECURITIES_SUCCESS,
  SECURITIES_FAILURE,
  PERMISSION_REQUEST,
  PERMISSION_SUCCESS,
  PERMISSION_FAILURE,
  GET_CANDIDATE_REQUEST,
  GET_CANDIDATE_SUCCESS,
  GET_CANDIDATE_HISTORY_BY_ID_REQUEST,
  GET_CANDIDATE_HISTORY_BY_ID_SUCCESS,
  GET_CANDIDATE_HISTORY_BY_ID_FAILURE,
  GET_ELECTROAL_DISTRICTS_BY_ID_REQUEST,
  GET_ELECTROAL_DISTRICTS_BY_ID_SUCCESS,
  GET_ELECTROAL_DISTRICTS_BY_ID_FAILURE,
  GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_FAILURE,
  GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_SUCCESS,
  GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_REQUEST,
  GET_PARTYBYID_REQUEST,
  GET_PARTYBYID_SUCCESS,
  GET_PARTYBYID_FAILURE,
  GET_POLLING_DIVISIONS_BY_ID_REQUEST,
  GET_POLLING_DIVISIONS_BY_ID_SUCCESS,
  GET_POLLING_DIVISIONS_BY_ID_FAILURE,
  GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST,
  GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS,
  GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE,
  GET_USERDETAILS_BYID_REQUEST,
  GET_USERDETAILS_BYID_SUCCESS,
  GET_USERDETAILS_BYID_FAILURE,
  GET_MANAGE_USERLIST_REQUEST,
  GET_MANAGE_USERLIST_SUCCESS,
  GET_MANAGE_USERLIST_FAILURE,
  GET_PERMISSIONS_BY_ID_REQUEST,
  GET_PERMISSIONS_BY_ID_SUCCESS,
  GET_PERMISSIONS_BY_ID_FAILURE,
  GET_CANDIDATEBYID_REQUEST,
  GET_CANDIDATEBYID_SUCCESS,
  GET_CANDIDATEBYID_FAILURE,
  GET_PARTIES_REQUEST,
  GET_PARTIES_SUCCESS,
  GET_PARTIES_FAILURE,
  GET_CANDIDATE_FAILURE,
  GET_ALL_PROVINCE_DISTRICTS_BY_ID_REQUEST,
  GET_ALL_PROVINCE_DISTRICTS_BY_ID_SUCCESS,
  GET_ALL_PROVINCE_DISTRICTS_BY_ID_FAILURE,
  GET_MANAGE_CANDIDATE_TOE_BY_ID_REQUEST,
  GET_MANAGE_CANDIDATE_TOE_BY_ID_SUCCESS,
  GET_MANAGE_CANDIDATE_TOE_BY_ID_FAILURE,
  GET_TYPE_OF_ELECTION_REQUEST,
  GET_TYPE_OF_ELECTION_SUCCESS,
  GET_TYPE_OF_ELECTION_FAILURE,
  GET_TYPE_OF_ELECTION_REQUEST_ONE,
  GET_TYPE_OF_ELECTION_SUCCESS_ONE,
  GET_TYPE_OF_ELECTION_FAILURE_ONE,
  GET_PARTY_HISTORY_BY_ID_REQUEST,
  GET_PARTY_HISTORY_BY_ID_SUCCESS,
  GET_PARTY_HISTORY_BY_ID_FAILURE,
  GET_ALL_PROVINCES_REQUEST,
  GET_ALL_PROVINCES_SUCCESS,
  GET_ALL_PROVINCES_FAILURE,
  GET_MANAGE_DISTRICTS_REQUEST,
  GET_MANAGE_DISTRICTS_SUCCESS,
  GET_MANAGE_DISTRICTS_FAILURE,
  GET_SECURITY_LEVEL_PERMISSIONS_REQUEST,
  GET_SECURITY_LEVEL_PERMISSIONS_SUCCESS,
  GET_SECURITY_LEVEL_PERMISSIONS_FAILURE,
  GET_DATAELECTIONRESULTS_BYID_FAILURE,
  GET_DATAELECTIONRESULTS_BYID_SUCCESS,
  GET_DATAELECTIONRESULTS_BYID_REQUEST,
  GET_ALL_BLOG_CATEGORY_REQUEST,
  GET_ALL_BLOG_CATEGORY_SUCCESS,
  GET_ALL_BLOG_CATEGORY_FAILURE,
  GET_BLOGCATEGORY_BYID_REQUEST,
  GET_BLOGCATEGORY_BYID_SUCCESS,
  GET_BLOGCATEGORY_BYID_FAILURE,
  GET_ALL_BLOG_CONTENT_REQUEST,
  GET_ALL_BLOG_CONTENT_SUCCESS,
  GET_ALL_BLOG_CONTENT_FAILURE,
  GET_BLOGCONTENT_BYID_FAILURE,
  GET_BLOGCONTENT_BYID_REQUEST,
  GET_BLOGCONTENT_BYID_SUCCESS,
  GET_ALL_ODEMOCARCY_POLL_RESULT_REQUEST,
  GET_ALL_ODEMOCARCY_POLL_RESULT_SUCCESS,
  GET_ALL_ODEMOCARCY_POLL_RESULT_FAILURE,
  GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_REQUEST,
  GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_SUCCESS,
  GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_FAILURE,
} from "./actionTypes";

const initialState = {
  loading: false,
  dashboardData: [],
  ShowSecurityLevelByIdData: [],
  indexSecurityData: [],
  indexPermissionData: [],
  ShowPermissionData: [],
  ShowCandidateByIdData: [],
  showCandidateHistoryByIdData: [],
  showDistrictByIdData: [],
  showMaCandidateByIdData: [],
  showAllProvinceDistrictsByIdData: [],
  showPartyByIdData: [],
  showsPollingDivisionsByIdData: [],
  showsAllPollingDivisionsByDistrictIdData: [],
  showUserDetailsByIdData: [],
  indexAllManageElectionData: [],
  indexPartiesData: [],
  indexCandidateData: [],
  indexAllElectionDetailsData: [],
  indexGetAllUserData: [],
  indexProvinceData: [],
  indexDistrictsData: [],
  getAllCandidateByTitleIdData: [],
  dataElectionResultsbyID: [],
  getAllBlogCategory: [],
  showBlogCategoryByIdData: [],
  getAllBlogContent: [],
  showBlogContentByIdData: [],
  getAllPresidentialElectionResultsByIdData: [],
  getAllOdemocracyPollResultsByIdData: [],
  getAllCandidateResultsByIdData: [],
  error: null,
};

const adminOneReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_DASHBOARD_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.payload,
        error: null,
      };
    case GET_DASHBOARD_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SECURITY_LEVEL_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SECURITY_LEVEL_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        ShowSecurityLevelByIdData: action.payload,
        error: null,
      };
    case GET_SECURITY_LEVEL_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SECURITIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SECURITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        indexSecurityData: action.payload,
        error: null,
      };
    case SECURITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_SECURITY_LEVEL_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SECURITY_LEVEL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        ShowSecurityLevelPermissionsByIdData: action.payload,
        error: null,
      };

    case GET_SECURITY_LEVEL_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        indexSecurityData: action.payload,
        error: null,
      };

    case PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        indexPermissionData: action.payload,
        error: null,
      };
    case PERMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PERMISSIONS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PERMISSIONS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        ShowPermissionData: action.payload,
        error: null,
      };
    case GET_PERMISSIONS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CANDIDATEBYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CANDIDATEBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        ShowCandidateByIdData: action.payload,
        error: null,
      };
    case GET_CANDIDATEBYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PARTIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PARTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        indexPartiesData: action.payload,
        error: null,
      };
    case GET_PARTIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PARTY_HISTORY_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PARTY_HISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        showGetAllPartyHistoryByPartyIdData: action.payload,
        error: null,
      };
    case GET_PARTY_HISTORY_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CANDIDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        indexCandidateData: action.payload,
        error: null,
      };
    case GET_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CANDIDATE_HISTORY_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CANDIDATE_HISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        showCandidateHistoryByIdData: action.payload,
        error: null,
      };
    case GET_CANDIDATE_HISTORY_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ELECTROAL_DISTRICTS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ELECTROAL_DISTRICTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        showDistrictByIdData: action.payload,
        error: null,
      };
    case GET_ELECTROAL_DISTRICTS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        showMaCandidateByIdData: action.payload,
        error: null,
      };
    case GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_PROVINCE_DISTRICTS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_PROVINCE_DISTRICTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        showAllProvinceDistrictsByIdData: action.payload,
        error: null,
      };
    case GET_ALL_PROVINCE_DISTRICTS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PARTYBYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PARTYBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        showPartyByIdData: action.payload,
        error: null,
      };
    case GET_PARTYBYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_POLLING_DIVISIONS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_POLLING_DIVISIONS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        showsPollingDivisionsByIdData: action.payload,
        error: null,
      };
    case GET_POLLING_DIVISIONS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        showsAllPollingDivisionsByDistrictIdData: action.payload,
        error: null,
      };
    case GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_MANAGE_CANDIDATE_TOE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_MANAGE_CANDIDATE_TOE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllCandidateByTitleIdData: action.payload,
        error: null,
      };
    case GET_MANAGE_CANDIDATE_TOE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_TYPE_OF_ELECTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TYPE_OF_ELECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        indexAllManageElectionData: action.payload,
        error: null,
      };
    case GET_TYPE_OF_ELECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_TYPE_OF_ELECTION_REQUEST_ONE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TYPE_OF_ELECTION_SUCCESS_ONE:
      return {
        ...state,
        loading: false,
        indexAllElectionDetailsData: action.payload,
        error: null,
      };
    case GET_TYPE_OF_ELECTION_FAILURE_ONE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USERDETAILS_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_USERDETAILS_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        showUserDetailsByIdData: action.payload,
        error: null,
      };
    case GET_USERDETAILS_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_MANAGE_USERLIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_MANAGE_USERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        indexGetAllUserData: action.payload,
        error: null,
      };
    case GET_MANAGE_USERLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_PROVINCES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ALL_PROVINCES_SUCCESS:
      return {
        ...state,
        loading: false,
        indexProvinceData: action.payload,
        error: null,
      };

    case GET_ALL_PROVINCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_MANAGE_DISTRICTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_MANAGE_DISTRICTS_SUCCESS:
      return {
        ...state,
        loading: false,
        indexDistrictsData: action.payload,
        error: null,
      };
    case GET_MANAGE_DISTRICTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_DATAELECTIONRESULTS_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_DATAELECTIONRESULTS_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataElectionResultsbyID: action.payload,
        error: null,
      };
    case GET_DATAELECTIONRESULTS_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_BLOG_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllBlogCategory: action.payload,
        error: null,
      };
    case GET_ALL_BLOG_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_BLOGCATEGORY_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_BLOGCATEGORY_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        showBlogCategoryByIdData: action.payload,
        error: null,
      };
    case GET_BLOGCATEGORY_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_BLOG_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_BLOG_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllBlogContent: action.payload,
        error: null,
      };
    case GET_ALL_BLOG_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_BLOGCONTENT_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_BLOGCONTENT_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        showBlogContentByIdData: action.payload,
        error: null,
      };
    case GET_BLOGCONTENT_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_ODEMOCARCY_POLL_RESULT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_ODEMOCARCY_POLL_RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllOdemocracyPollResultsByIdData: action.payload,
        error: null,
      };
    case GET_ALL_ODEMOCARCY_POLL_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPresidentialElectionResultsByIdData: action.payload,
        error: null,
      };
    case GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ALLCANDIDATERESULTS_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALLCANDIDATERESULTS_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllCandidateResultsByIdData: action.payload,
        error: null,
      };
    case GET_ALLCANDIDATERESULTS_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "CLEAR_ELECTION_RESULTS":
      return {
        ...state,
        dataElectionResultsbyID: null,
      };
    default:
      return state;
  }
};

export default adminOneReducer;
