import React, { useEffect, useState } from "react";
import OrangYellowBtn from "../../../components/buttons/orangeSolidBtn";
import VoteForYourCandidate from "../../../components/modals/voteForYourCandidate";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { addRegisterVoterId } from "../../../redux/user/action";
import { indexGetAllManageElections } from "../../../redux/adminone/action";
import Loader from "../../../components/loaders/loader";
import { FaLocationArrow } from "react-icons/fa6";
import HeroResults from "../../../components/user/parliamentElection/heroResults";
import CardSix from "../../../components/cards/cardSix";
import { Col, Container, Row } from "react-bootstrap";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    Width: "fit-content",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

function HeroSectionParliament() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  // const {
  //   electionTypeId,
  //   electionTitleId,
  //   title,
  //   poll_start_date,
  //   poll_end_date,
  //   election_start_date,
  //   election_end_date,
  // } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState("");
  const [userData, setUserData] = useState(null);
  const [openSnackbar] = useSnackbar();
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [options, setOptions] = useState([]);

  const [electionDates, setElectionDates] = useState({
    election_start_date: "",
    election_end_date: "",
    poll_start_date: "",
    poll_end_date: "",
  });

  const indexAllManageElectionData = useSelector(
    (state) => state.adminone.indexAllManageElectionData?.data
  );

  const loadingA = useSelector((state) => state.adminone.loading);
  const loadingU = useSelector((state) => state.user.loading);
  const isLoading = loadingA || loadingU;

  const [formData, setFormData] = useState({
    voter_id: "",
  });
  const [errors, setErrors] = useState({
    voter_id: "",
  });

  useEffect(() => {
    dispatch(indexGetAllManageElections());
  }, [dispatch]);

  const getFilteredElections = (elections) => {
    return elections.filter((election) => election.election_type_id === 2);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserData(parsedUser);
      setUsername(parsedUser.name);
      setFormData({ voter_id: parsedUser.voter_id || "" });

      if (Array.isArray(indexAllManageElectionData)) {
        const filteredElections = getFilteredElections(
          indexAllManageElectionData
        );
        const newOptions = filteredElections.map((election) => ({
          value: election.election_type_id,
          label: election.election_title,
          election_start_date: election.election_start_date,
          election_end_date: election.election_end_date,
          poll_start_date: election.poll_start_date,
          poll_end_date: election.poll_end_date,
        }));
        setOptions(newOptions);

        if (!selectedFilter) {
          if (location.state?.title) {
            const selectedOption = newOptions.find(
              (option) => option.label === location.state.title
            );
            if (selectedOption) {
              setSelectedFilter(selectedOption);
              setElectionDates({
                election_start_date: selectedOption.election_start_date,
                election_end_date: selectedOption.election_end_date,
                poll_start_date: selectedOption.poll_start_date,
                poll_end_date: selectedOption.poll_end_date,
              });
            }
          } else if (location.state?.electionTitleId) {
            const selectedOption = newOptions.find(
              (option) => option.value === location.state.electionTitleId
            );
            if (selectedOption) {
              setSelectedFilter(selectedOption);
              setElectionDates({
                election_start_date: selectedOption.election_start_date,
                election_end_date: selectedOption.election_end_date,
                poll_start_date: selectedOption.poll_start_date,
                poll_end_date: selectedOption.poll_end_date,
              });
            }
          } else if (newOptions.length > 0) {
            setSelectedFilter(newOptions[0]);
            setElectionDates({
              election_start_date: newOptions[0].election_start_date,
              election_end_date: newOptions[0].election_end_date,
              poll_start_date: newOptions[0].poll_start_date,
              poll_end_date: newOptions[0].poll_end_date,
            });
          }
        }
      }
    }
  }, [indexAllManageElectionData, location.state, selectedFilter]);

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);

    setElectionDates({
      election_start_date: selectedOption.election_start_date,
      election_end_date: selectedOption.election_end_date,
      poll_start_date: selectedOption.poll_start_date,
      poll_end_date: selectedOption.poll_end_date,
    });
  };

  const handleShowModal = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      if (parsedUser.voter_id) {
        navigate("/choose-party", {
          state: {
            electionTypeId: parsedUser.electionTypeId,
            title: selectedFilter?.label || "Election Title",
            district_id: parsedUser.district_id,
            district_name: parsedUser.district_name,
            polling_division_id: parsedUser.polling_division_id,
            polling_division_name: parsedUser.polling_division_name,
            voter_id: parsedUser.voter_id,
          },
        });
      } else {
        setShowModal(true);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleClick = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const { voter_id } = formData;
    let valid = true;
    let newErrors = {};

    if (!voter_id || voter_id.length < 2) {
      newErrors.voter_id = "Voter ID must be at least 2 characters long.";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;

    const formDataVoter = new FormData();
    formDataVoter.append("name", username);
    formDataVoter.append("voter_id", voter_id);

    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const userId = parsedUser.id;

        if (!userId) {
          openSnackbar("User ID not found");
          return;
        }

        dispatch(
          addRegisterVoterId(formDataVoter, userId, (error, response) => {
            if (error) {
              console.error("Error:", error);
              openSnackbar("Error occurred while adding voter ID");
            } else {
              if (response.status === 200) {
                openSnackbar(response.data.message);
                parsedUser.voter_id = voter_id;
                localStorage.setItem("user", JSON.stringify(parsedUser));
                setShowModal(false);
                navigate("/choose-party", {
                  state: {
                    electionTypeId: parsedUser.electionTypeId,
                    title: selectedFilter?.label || "Election Title",
                    district_id: parsedUser.district_id,
                    district_name: parsedUser.district_name,
                    polling_division_id: parsedUser.polling_division_id,
                    polling_division_name: parsedUser.polling_division_name,
                  },
                });
              } else {
                console.error("Response error:", response);
                openSnackbar("Authorization failed.");
              }
            }
          })
        );
      } catch (error) {
        openSnackbar("User ID not found");
      }
    } else {
      openSnackbar("User ID not found");
    }
  };

  const contentTabs = {
    provincialDetailsData: [
      { label: "Registered Electors", value: `52,000` },
      { label: "Total Valid Votes", value: `30,467` },
      { label: "Total Rejected Votes", value: `1,448` },
    ],
  };
  const totalVotes = 100;
  const votesReceived = 88;
  const percentage = (votesReceived / totalVotes) * 100;

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const text = {
    title: "Sri Lanka",
    parties: "4 Leading parties",
    seats: "196 seats",
  };

  const data = [
    { party: "Muslim Minority Politics", votes: 200 },
    { party: "National Party", votes: 150 },
    { party: "Bhartiya Janta Party", votes: 125 },
    { party: "Minority Cast Party", votes: 80 },
  ];

  const isPollEnded = new Date() > new Date(electionDates.poll_end_date);

  const props = {
    cstomStyle,
    value: selectedFilter,
    selectChange: handleFilterChange,
    options,
    contentTabs,
    percentage: parseFloat(percentage),
    strokeColor: "orange",
  };

  return (
    <div className="Hero-Section-parliament mb-5">
      <div className="d-flex mb-4">
        <p className="backG"></p>
        <div className="hero-disc">
          <p>
            <span>Note :</span> O'Democracy polling is an exclusive platform
            poll, and only O'Democracy registered users have the cast their
            vote.
          </p>
        </div>
      </div>
      <div className="d-flex mb-5">
        <p className="backG"></p>
        <div className="hero-disc">
          <span>Election start date :</span>{" "}
          {formatDate(electionDates.election_start_date)} &nbsp;
          <span>end date :</span> {formatDate(electionDates.election_end_date)}
          <span className="mx-5">||</span>
          <span>Poll start date :</span>{" "}
          {formatDate(electionDates.poll_start_date)} &nbsp;
          <span>end date :</span> {formatDate(electionDates.poll_end_date)}
        </div>
      </div>
      <div className="d-lg-flex d-block justify-content-between align-items-center first-div">
        <div className="hero-h5">
          <h5>
            {selectedFilter?.label || location.state?.title || "Election Title"}{" "}
            <span>(Poll)</span>
          </h5>
          <p>
            Sri Lanka, {userData?.district_name},{" "}
            {userData?.polling_division_name}{" "}
            <FaLocationArrow
              className="location-i"
              onClick={() => {
                navigate(`/select-election`);
              }}
            />
          </p>
        </div>
        <div className="hero-btns text-end mt-lg-0 mt-4">
          {!isPollEnded ? (
            <OrangYellowBtn onClick={handleShowModal} disabled={isLoading}>
              Vote for your candidate
            </OrangYellowBtn>
          ) : (
            <OrangYellowBtn
              onClick={() => navigate("/parliamentresult")}
              disabled={isLoading}
            >
              View Exit Poll Result
            </OrangYellowBtn>
          )}
        </div>
      </div>
      <VoteForYourCandidate
        show={showModal}
        onHide={() => setShowModal(false)}
        title="Register as a voter"
        nameLabel="Voter name"
        namePlaceholder="Enter your profile name"
        idPlaceholder="Enter your Voter ID"
        formValue={username}
        formValue1={formData.voter_id}
        handleChange={handleChange}
        handleClick={handleClick}
        voteText="Save"
      />
      <HeroResults {...props} />
      <Container fluid className="cards-result">
        <Row md={3}>
          <Col>
            <CardSix text={text} data={data} />
          </Col>
          <Col>
            <CardSix text={text} data={data} />
          </Col>
          <Col>
            <CardSix text={text} data={data} />
          </Col>
        </Row>
      </Container>
      {isLoading ? (
        <Loader
          color={"#0E76A8"}
          loading={isLoading}
          css={override}
          size={75}
        />
      ) : null}
    </div>
  );
}

export default HeroSectionParliament;
