import React, { useState, useEffect, useRef } from "react";
import { Container, Dropdown, DropdownButton, Image, Nav, Navbar } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import Icon from "../../assets/images/Icon.png";
import { GrHomeRounded, GrLocation } from "react-icons/gr";
import { MdOutlineHowToVote } from "react-icons/md";
import EndCanvas from "../endCanvas";
import CustomSelect from "../select/customSelect";
import VoteSimulator from "./voteSimulator";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaRegUser } from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/user/action";
import { cApiUrlPrefix } from "../../config/envConfig";
import { indexGetAllManageElections } from "../../redux/adminone/action";
import Loader from "../loaders/loader";

const options = [
  { value: "Sri", label: "Srilanka" },
  { value: "Ind", label: "India" },
  { value: "BD", label: "Bangladesh" },
  { value: "CHN", label: "China" },
];

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

const UHeader = ({ lang, changeLang }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userImg = user?.user_image
  const [showVoteSimulator, setShowVoteSimulator] = useState(false);
  const voteSimulatorRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(indexGetAllManageElections());
  }, [dispatch]);

  const indexAllManageElectionData = useSelector(
    (state) => state.adminone.indexAllManageElectionData?.data
  );
  const loading = useSelector((state) => state.adminone.loading);

  const uniqueElections = indexAllManageElectionData
    ?.map(election => ({
      election_type_id: election.election_type_id,
      type_of_election_name: election.type_of_election_name
    }))
    .filter((election, index, self) =>
      self.findIndex(e => e.election_type_id === election.election_type_id) === index
    );




  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login-perefrance");
    localStorage.removeItem("token");
  };

  const options2 = [
    { value: "Tam", label: "tamil" },
    { value: "Hin", label: "hindi" },
    { value: "En", label: "english" },
  ];

  const handleVoteClick = () => {
    setShowVoteSimulator((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (
      voteSimulatorRef.current &&
      !voteSimulatorRef.current.contains(event.target)
    ) {
      setShowVoteSimulator(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Navbar expand="lg" className="Header-main overlap-navbar">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between align-items-center w-100">
          <Navbar.Brand as={Link} to="/" className="d-lg-block d-none">
            <Image className="logo mainLogo" src={Logo}></Image>
          </Navbar.Brand>
          <Navbar.Brand as={Link} to="/" className="d-lg-none d-block">
            <Image className="logo" src={Icon}></Image>
          </Navbar.Brand>
          <Nav className="d-flex flex-row justify-content-end align-items-center">
            <Nav.Link as={Link} to="/select-election" className="d-md-block d-none ms-md-4 ms-3 px-md-4 px-2">
              <GrHomeRounded className="home-icon" />
            </Nav.Link>
            <Nav.Link as={Link} to="/select-election" className="ms-md-3 ms-2 px-md-4 px-2">
              <GrLocation className="home-icon" />
            </Nav.Link>
            <Nav.Link
              className="ms-md-3 ms-2 px-md-4 px-2"
              onClick={handleVoteClick}
            >
              <MdOutlineHowToVote className="home-icon" />
            </Nav.Link>
            {showVoteSimulator && (
              <div ref={voteSimulatorRef} className="vote-wrapper">
                <VoteSimulator uniqueElections={uniqueElections} />
              </div>
            )}
            <Nav.Link className="ms-md-3 ms-2">
              {["end"].map((placement, idx) => (
                <EndCanvas
                  key={idx}
                  placement={placement}
                  name={placement}
                />
              ))}
            </Nav.Link>
            <Nav.Link href="#link" className="ms-md-3 ms-2">
              <CustomSelect
                options={options}
                value="Sri"
                placeholder="Select a country"
              />
            </Nav.Link>
            <Nav.Link href="#link" className="ms-md-3 ms-2">
              <CustomSelect
                options={options2}
                placeholder={t("languageNames.english")}
                onChange={changeLang}
                value="En"
              />
            </Nav.Link>

            <DropdownButton
              className="AprofileBtn d-none d-xl-block"
              id="dropdown-basic-button"
              title={
                <div className="aloginbtn">
                  {" "}
                  <div className="loginBlk">
                    <Image src={cApiUrlPrefix + userImg} fluid className="plogo" />
                  </div>{" "}
                  <FaBars className="loginbar" />
                </div>
              }
            >
              <Dropdown.Item className="mUlist " as={Link} to="/profile">
                <FaRegUser className="pm" /> Profile Manage
              </Dropdown.Item>

              <Dropdown.Item
                className="mUlist"

                href="#/action-3"
                onClick={handleLogout}
              >
                <MdLogout className="lo" /> Logout
              </Dropdown.Item>


            </DropdownButton>

          </Nav>
        </div>
      </Container>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </Navbar>
  );
};

export default UHeader;
