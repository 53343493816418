import React, { useEffect, useState } from "react";

import ManageBlk from "../../../components/admin/manageBlk";
import { useDispatch, useSelector } from "react-redux";
import InActive from "../../../components/labels/inActive";
import Active from "../../../components/labels/active";
import DropDown from "../../../components/dropdown/dropDown";
import { indexGetAllManageElections } from "../../../redux/adminone/action";
import DateFormatter from "../../../utilities/dateFormatter";
import { useNavigate } from "react-router-dom";

const formatDate = (dateString) => {
  const options = { day: 'numeric', month: 'short' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];

const cstomStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "#65676B",
    maxWidth: "97px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#efefef" : "white",
    color: state.isFocused ? "#65676B" : "#65676B",
    padding: 5,
  }),
};

const TypeOfElectionn = () => {
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(indexGetAllManageElections());


  }, [dispatch]);
  const navigate = useNavigate();
  const [isSearchable] = useState(false);
  const indexAllManageElectionData = useSelector(
    (state) => state.adminone.indexAllManageElectionData?.data
  );
  const error = useSelector((state) => state.adminone.error);

  const statusMap = {
    0: <InActive />,
    1: <Active />,
  };

  const dropdownItems = (data) => [
    { label: "Edit", href: "#/edit", onClick: () => handleEdit(data) },
    { label: "Manage Candidates", href: "#/action-3", onClick: () => handleManageCandidate(data) },
    { label: "Exit Poll Results", href: "#/action-3", onClick: () => handleExitPollId(data) },
  ];

  const handleEdit = (data) => {
    navigate("/edit-election-type", {
      state: {
        id: data.id,
        edit: true,
      }
    });


    // navigate(
    //   `/edit-election-type?id=${data.id}&edit=true`
    // );
  };

  const handleManageCandidate = (data) => {
    navigate("/type-of-election-candidate", {
      state: {
        id: data.id,
        type_of_election_name: data.type_of_election_name,
        election_title: data.election_title
      }
    });

    // navigate(
    //   `/type-of-election-candidate?id=${data.id}&type_of_election_name=${encodeURIComponent(data.type_of_election_name)}&election_title=${encodeURIComponent(data.election_title)}`
    // );
  };

  const handleExitPollId = (data) => {
    const electionTypeId = data.id;
    navigate("/manage-presidential-election", { state: { electionTypeId } });
  };


  const labels = [
    "Election Type",
    "Election Title",
    "Election Date",
    "Poll Date",
    "Status",
    "Action",
  ];
  const data = Array.isArray(indexAllManageElectionData)
    ? indexAllManageElectionData.map((typeOfElection) => ({
      "Election Type": typeOfElection.type_of_election_name,
      "Election Title": typeOfElection.election_title,
      "Election Date": (
        <DateFormatter
          startDate={typeOfElection.election_start_date}
          endDate={typeOfElection.election_end_date}
        />
      ),
      "Poll Date": (
        <DateFormatter
          startDate={typeOfElection.poll_start_date}
          endDate={typeOfElection.poll_end_date}
        />
      ),
      Status: statusMap[typeOfElection.status],
      Action: (
        <DropDown
          Children={"Action"}
          items={dropdownItems(typeOfElection)}
          className="typeOfElectionAction ms-auto"
        />
      ),
    }))
    : [];

  const managePartyBlkProps = {
    title: "Type Of Election",
    children: "Add Election Type",
    to: "/add-election-type",
    style: { padding: "1.3rem 2.8rem" },
    options,
    isSearchable,
    className: {
      cselectpageSize: "cselectpageSize",
      minputSearch: "minputSearch",
      deleteModal: "deleteModal",
    },
    cstomStyle,
    Placeholder: "Search",
    labels,
    data,
    error
  };
  return (
    <main className="toelection">
      <aside className="toelectionaside">
        <ManageBlk {...managePartyBlkProps} />
      </aside>

    </main>
  );
};

export default TypeOfElectionn;
