export const LOGIN_ADMIN_REQUEST = "LOGIN_ADMIN_REQUEST";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILURE = "LOGIN_ADMIN_FAILURE";

export const GET_DASHBOARD_ADMIN_REQUEST = "GET_DASHBOARD_ADMIN_REQUEST";
export const GET_DASHBOARD_ADMIN_SUCCESS = "GET_DASHBOARD_ADMIN_SUCCESS";
export const GET_DASHBOARD_ADMIN_FAILURE = "GET_DASHBOARD_ADMIN_FAILURE";

export const ADD_SECURITIES_REQUEST = "ADD_SECURITIES_REQUEST";
export const ADD_SECURITIES_SUCCESS = "ADD_SECURITIES_SUCCESS";
export const ADD_SECURITIES_FAILURE = "ADD_SECURITIES_FAILURE";

export const SECURITIES_REQUEST = "SECURITIES_REQUEST";
export const SECURITIES_SUCCESS = "SECURITIES_SUCCESS";
export const SECURITIES_FAILURE = "SECURITIES_FAILURE";

export const GET_SECURITY_LEVEL_DETAILS_BY_ID_REQUEST =
  "GET_SECURITY_LEVEL_DETAILS_BY_ID_REQUEST";
export const GET_SECURITY_LEVEL_DETAILS_BY_ID_SUCCESS =
  "GET_SECURITY_LEVEL_DETAILS_BY_ID_SUCCESS";
export const GET_SECURITY_LEVEL_DETAILS_BY_ID_FAILURE =
  "GET_SECURITY_LEVEL_DETAILS_BY_ID_FAILURE";

export const UPDATE_SECURITYLEVEL_PERMISSION_REQUEST =
  "UPDATE_SECURITYLEVEL_PERMISSION_REQUEST";
export const UPDATE_SECURITYLEVEL_PERMISSION_SUCCESS =
  "UPDATE_SECURITYLEVEL_PERMISSION_SUCCESS";
export const UPDATE_SECURITYLEVEL_PERMISSION_FAILURE =
  "UPDATE_SECURITYLEVEL_PERMISSION_FAILURE";

export const GET_SECURITY_LEVEL_PERMISSIONS_REQUEST =
  "GET_SECURITY_LEVEL_PERMISSIONS_REQUEST";
export const GET_SECURITY_LEVEL_PERMISSIONS_SUCCESS =
  "GET_SECURITY_LEVEL_PERMISSIONS_SUCCESS";
export const GET_SECURITY_LEVEL_PERMISSIONS_FAILURE =
  "GET_SECURITY_LEVEL_PERMISSIONS_FAILURE";

export const POST_UPDATE_SECURITY_LEVEL_BY_ID_REQUEST =
  "POST_UPDATE_SECURITY_LEVEL_BY_ID_REQUEST";
export const POST_UPDATE_SECURITY_LEVEL_BY_ID_SUCCESS =
  "POST_UPDATE_SECURITY_LEVEL_BY_ID_SUCCESS";
export const POST_UPDATE_SECURITY_LEVEL_BY_ID_FAILURE =
  "POST_UPDATE_SECURITY_LEVEL_BY_ID_FAILURE";

export const DEL_SECURITIES_BY_ID_REQUEST = "DEL_SECURITIES_BY_ID_REQUEST";

export const ADD_PERMISSION_REQUEST = "ADD_PERMISSION_REQUEST";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_FAILURE = "ADD_PERMISSION_FAILURE";

export const PERMISSION_REQUEST = "PERMISSION_REQUEST";
export const PERMISSION_SUCCESS = "PERMISSION_SUCCESS";
export const PERMISSION_FAILURE = "PERMISSION_FAILURE";

export const ADD_SECURITYLEVEL_PERMISSION_REQUEST =
  "ADD_SECURITYLEVEL_PERMISSION_REQUEST";
export const ADD_SECURITYLEVEL_PERMISSION_SUCCESS =
  "ADD_SECURITYLEVEL_PERMISSION_SUCCESS";
export const ADD_SECURITYLEVEL_PERMISSION_FAILURE =
  "ADD_SECURITYLEVEL_PERMISSION_FAILURE";

export const GET_PERMISSIONS_BY_ID_REQUEST = "GET_PERMISSIONS_BY_ID_REQUEST";
export const GET_PERMISSIONS_BY_ID_SUCCESS = "GET_PERMISSIONS_BY_ID_SUCCESS";
export const GET_PERMISSIONS_BY_ID_FAILURE = "GET_PERMISSIONS_BY_ID_FAILURE";

export const POST_UPDATE_PERMISSION_BY_ID_REQUEST =
  "POST_UPDATE_PERMISSION_BY_ID_REQUEST";
export const POST_UPDATE_PERMISSION_BY_ID_SUCCESS =
  "POST_UPDATE_PERMISSION_BY_ID_SUCCESS";
export const POST_UPDATE_PERMISSION_BY_ID_FAILURE =
  "POST_UPDATE_PERMISSION_BY_ID_FAILURE";

export const DEL_PERMISSION_BY_ID_REQUEST = "DEL_PERMISSION_BY_ID_REQUEST";

export const GET_PARTIES_REQUEST = "GET_PARTIES_REQUEST";
export const GET_PARTIES_SUCCESS = "GET_PARTIES_SUCCESS";
export const GET_PARTIES_FAILURE = "GET_PARTIES_FAILURE";

export const GET_PARTYBYID_REQUEST = "GET_PARTYBYID_REQUEST";
export const GET_PARTYBYID_SUCCESS = "GET_PARTYBYID_SUCCESS";
export const GET_PARTYBYID_FAILURE = "GET_PARTYBYID_FAILURE";

export const UPDATE_PARTY_REQUEST = "UPDATE_PARTY_REQUEST";
export const UPDATE_PARTY_SUCCESS = "UPDATE_PARTY_SUCCESS";
export const UPDATE_PARTY_FAILURE = "UPDATE_PARTY_FAILURE";

export const POST_MANAGEPARTIES_REQUEST = "POST_MANAGEPARTIES_REQUEST";
export const POST_MANAGEPARTIES_SUCCESS = "POST_MANAGEPARTIES_SUCCESS";
export const POST_MANAGEPARTIES_FAILURE = "POST_MANAGEPARTIES_FAILURE";

export const GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_REQUEST =
  "GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_REQUEST";
export const GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_SUCCESS =
  "GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_SUCCESS";
export const GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_FAILURE =
  "GET_MANAGE_ASSOCIATED_CANDIDATE_BY_ID_FAILURE";

export const DELETE_PARTY_REQUEST = "DELETE_PARTY_REQUEST";
export const DELETE_PARTY_SUCCESS = "DELETE_PARTY_SUCCESS";
export const DELETE_PARTY_FAILURE = "DELETE_PARTY_FAILURE";

export const GET_PARTY_HISTORY_BY_ID_REQUEST =
  "GET_PARTY_HISTORY_BY_ID_REQUEST";
export const GET_PARTY_HISTORY_BY_ID_SUCCESS =
  "GET_PARTY_HISTORY_BY_ID_SUCCESS";
export const GET_PARTY_HISTORY_BY_ID_FAILURE =
  "GET_PARTY_HISTORY_BY_ID_FAILURE";

export const POST_PARTY_HISTORY_REQUEST = "POST_PARTY_HISTORY_REQUEST";
export const POST_PARTY_HISTORY_SUCCESS = "POST_PARTY_HISTORY_SUCCESS";
export const POST_PARTY_HISTORY_FAILURE = "POST_PARTY_HISTORY_FAILURE";

export const UPDATE_PARTY_HISTORY_REQUEST = "UPDATE_PARTY_HISTORY_REQUEST";
export const UPDATE_PARTY_HISTORY_SUCCESS = "UPDATE_PARTY_HISTORY_SUCCESS";
export const UPDATE_PARTY_HISTORY_FAILURE = "UPDATE_PARTY_HISTORY_FAILURE";

export const DEL_PARTY_HISTORY_REQUEST = "DEL_PARTY_HISTORY_REQUEST";
export const DEL_PARTY_HISTORY_SUCCESS = "DEL_PARTY_HISTORY_SUCCESS";
export const DEL_PARTY_HISTORY_FAILURE = "DEL_PARTY_HISTORY_FAILURE";

export const GET_CANDIDATE_REQUEST = "GET_CANDIDATE_REQUEST";
export const GET_CANDIDATE_SUCCESS = "GET_CANDIDATE_SUCCESS";
export const GET_CANDIDATE_FAILURE = "GET_CANDIDATE_FAILURE";

export const GET_CANDIDATEBYID_REQUEST = "GET_CANDIDATEBYID_REQUEST";
export const GET_CANDIDATEBYID_SUCCESS = "GET_CANDIDATEBYID_SUCCESS";
export const GET_CANDIDATEBYID_FAILURE = "GET_CANDIDATEBYID_FAILURE";

export const UPDATE_CANDIDATEBYID_REQUEST = "UPDATE_CANDIDATEBYID_REQUEST";
export const UPDATE_CANDIDATEBYID_SUCCESS = "UPDATE_CANDIDATEBYID_SUCCESS";
export const UPDATE_CANDIDATEBYID_FAILURE = "UPDATE_CANDIDATEBYID_FAILURE";

export const POST_CANDIDATE_REQUEST = "POST_CANDIDATE_REQUEST";
export const POST_CANDIDATE_SUCCESS = "POST_CANDIDATE_SUCCESS";
export const POST_CANDIDATE_FAILURE = "POST_CANDIDATE_FAILURE";

export const DELETE_CANDIDATE_REQUEST = "DELETE_CANDIDATE_REQUEST";
export const DELETE_CANDIDATE_SUCCESS = "DELETE_CANDIDATE_SUCCESS";
export const DELETE_CANDIDATE_FAILURE = "DELETE_CANDIDATE_REQUEST";

export const GET_CANDIDATE_HISTORY_BY_ID_REQUEST =
  "GET_CANDIDATE_HISTORY_BY_ID_REQUEST";
export const GET_CANDIDATE_HISTORY_BY_ID_SUCCESS =
  "GET_CANDIDATE_HISTORY_BY_ID_SUCCESS";
export const GET_CANDIDATE_HISTORY_BY_ID_FAILURE =
  "GET_CANDIDATE_HISTORY_BY_ID_FAILURE";

export const POST_CANDIDATE_HISTORY_REQUEST = "POST_CANDIDATE_HISTORY_REQUEST";
export const POST_CANDIDATE_HISTORY_SUCCESS = "POST_CANDIDATE_HISTORY_SUCCESS";
export const POST_CANDIDATE_HISTORY_FAILURE = "POST_CANDIDATE_HISTORY_FAILURE";

export const UPDATE_CANDIDATE_HISTORY_REQUEST =
  "UPDATE_CANDIDATE_HISTORY_REQUEST";
export const UPDATE_CANDIDATE_HISTORY_SUCCESS =
  "UPDATE_CANDIDATE_HISTORY_SUCCESS";
export const UPDATE_CANDIDATE_HISTORY_FAILURE =
  "UPDATE_CANDIDATE_HISTORY_FAILURE";

export const DEL_CANDIDATE_HISTORY_REQUEST = "DEL_CANDIDATE_HISTORY_REQUEST";
export const DEL_CANDIDATE_HISTORY_SUCCESS = "DEL_CANDIDATE_HISTORY_SUCCESS";
export const DEL_CANDIDATE_HISTORY_FAILURE = "DEL_CANDIDATE_HISTORY_FAILURE";

export const POST_MANAGE_PROVINCE_REQUEST = "POST_MANAGE_PROVINCE_REQUEST";
export const POST_MANAGE_PROVINCE_SUCCESS = "POST_MANAGE_PROVINCE_SUCCESS";
export const POST_MANAGE_PROVINCE_FAILURE = "POST_MANAGE_PROVINCE_FAILURE";

export const POST_UPDATE_PROVINCES_BY_PROVINCES_ID_REQUEST =
  "POST_UPDATE_PROVINCES_BY_PROVINCES_ID_REQUEST";
export const POST_UPDATE_PROVINCES_BY_PROVINCES_ID_SUCCESS =
  "POST_UPDATE_PROVINCES_BY_PROVINCES_ID_SUCCESS";
export const POST_UPDATE_PROVINCES_BY_PROVINCES_ID_FAILURE =
  "POST_UPDATE_PROVINCES_BY_PROVINCES_ID_FAILURE";

export const GET_ALL_PROVINCES_REQUEST = "GET_ALL_PROVINCES_REQUEST";
export const GET_ALL_PROVINCES_SUCCESS = "GET_ALL_PROVINCES_SUCCESS";
export const GET_ALL_PROVINCES_FAILURE = "GET_ALL_PROVINCES_FAILURE";

export const GET_ALL_PROVINCE_DISTRICTS_BY_ID_REQUEST =
  "GET_ALL_PROVINCE_DISTRICTS_BY_ID_REQUEST";
export const GET_ALL_PROVINCE_DISTRICTS_BY_ID_SUCCESS =
  "GET_ALL_PROVINCE_DISTRICTS_BY_ID_SUCCESS";
export const GET_ALL_PROVINCE_DISTRICTS_BY_ID_FAILURE =
  "GET_ALL_PROVINCE_DISTRICTS_BY_ID_FAILURE";

export const DEL_MANAGE_PROVINCE_BY_ID_REQUEST =
  "DEL_MANAGE_PROVINCE_BY_ID_REQUEST";
export const DEL_MANAGE_PROVINCE_BY_ID_SUCCESS =
  "DEL_MANAGE_PROVINCE_BY_ID_SUCCESS";
export const DEL_MANAGE_PROVINCE_BY_ID_FAILURE =
  "DEL_MANAGE_PROVINCE_BY_ID_FAILURE";

export const POST_MANAGE_DISTRICTS_REQUEST = "POST_MANAGE_DISTRICTS_REQUEST";
export const POST_MANAGE_DISTRICTS_SUCCESS = "POST_MANAGE_DISTRICTS_SUCCESS";
export const POST_MANAGE_DISTRICTS_FAILURE = "POST_MANAGE_DISTRICTS_FAILURE";

export const GET_MANAGE_DISTRICTS_REQUEST = "GET_MANAGE_DISTRICTS_REQUEST";
export const GET_MANAGE_DISTRICTS_SUCCESS = "GET_MANAGE_DISTRICTS_SUCCESS";
export const GET_MANAGE_DISTRICTS_FAILURE = "GET_MANAGE_DISTRICTS_FAILURE";

export const POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_REQUEST =
  "POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_REQUEST";
export const POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_SUCCESS =
  "POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_SUCCESS";
export const POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_FAILURE =
  "POST_UPDATE_ELECTROL_DISTRICTS_BY_ID_FAILURE";

export const GET_ELECTROAL_DISTRICTS_BY_ID_REQUEST =
  "GET_ELECTROAL_DISTRICTS_BY_ID_REQUEST";
export const GET_ELECTROAL_DISTRICTS_BY_ID_SUCCESS =
  "GET_ELECTROAL_DISTRICTS_BY_ID_SUCCESS";
export const GET_ELECTROAL_DISTRICTS_BY_ID_FAILURE =
  "GET_ELECTROAL_DISTRICTS_BY_ID_FAILURE";

export const DEL_MANAGE_DISTRICTS_BY_ID_REQUEST =
  "DEL_MANAGE_DISTRICTS_BY_ID_REQUEST";
export const DEL_MANAGE_DISTRICTS_BY_ID_SUCCESS =
  "DEL_MANAGE_DISTRICTS_BY_ID_SUCCESS";
export const DEL_MANAGE_DISTRICTS_BY_ID_FAILURE =
  "DEL_MANAGE_DISTRICTS_BY_ID_FAILURE";

export const POST_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST =
  "POST_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST";
export const POST_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS =
  "POST_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS";
export const POST_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE =
  "POST_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE";

export const GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST =
  "GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST";
export const GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS =
  "GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS";
export const GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE =
  "GET_ALL_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE";

export const POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST =
  "POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST";
export const POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS =
  "POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS";
export const POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE =
  "POST_UPDATE_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE";

export const GET_POLLING_DIVISIONS_BY_ID_REQUEST =
  "GET_POLLING_DIVISIONS_BY_ID_REQUEST";
export const GET_POLLING_DIVISIONS_BY_ID_SUCCESS =
  "GET_POLLING_DIVISIONS_BY_ID_SUCCESS";
export const GET_POLLING_DIVISIONS_BY_ID_FAILURE =
  "GET_POLLING_DIVISIONS_BY_ID_FAILURE";

export const POST_UPDATE_ELECTION_CANDIDATES_BY_ID_REQUEST =
  "POST_UPDATE_ELECTION_CANDIDATES_BY_ID_REQUEST";
export const POST_UPDATE_ELECTION_CANDIDATES_BY_ID_SUCCESS =
  "POST_UPDATE_ELECTION_CANDIDATES_BY_ID_SUCCESS";
export const POST_UPDATE_ELECTION_CANDIDATES_BY_ID_FAILURE =
  "POST_UPDATE_ELECTION_CANDIDATES_BY_ID_FAILURE";

export const DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST =
  "DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_REQUEST";
export const DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS =
  "DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_SUCCESS";
export const DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE =
  "DEL_POLLING_DIVISIONS_BY_DISTRICT_ID_FAILURE";

export const GET_TYPE_OF_ELECTION_REQUEST = "GET_TYPE_OF_ELECTION_REQUEST";
export const GET_TYPE_OF_ELECTION_SUCCESS = "GET_TYPE_OF_ELECTION_SUCCESS";
export const GET_TYPE_OF_ELECTION_FAILURE = "GET_TYPE_OF_ELECTION_FAILURE";

export const GET_TYPE_OF_ELECTION_REQUEST_ONE =
  "GET_TYPE_OF_ELECTION_REQUEST_ONE";
export const GET_TYPE_OF_ELECTION_SUCCESS_ONE =
  "GET_TYPE_OF_ELECTION_SUCCESS_ONE";
export const GET_TYPE_OF_ELECTION_FAILURE_ONE =
  "GET_TYPE_OF_ELECTION_FAILURE_ONE";

export const POST_MANAGE_ELECTION_TYPE_ID_REQUEST =
  "POST_MANAGE_ELECTION_TYPE_ID_REQUEST";
export const POST_MANAGE_ELECTION_TYPE_ID_SUCCESS =
  "POST_MANAGE_ELECTION_TYPE_ID_SUCCESS";
export const POST_MANAGE_ELECTION_TYPE_ID_FAILURE =
  "POST_MANAGE_ELECTION_TYPE_ID_FAILURE";

export const GET_MANAGE_ELECTION_REQUEST = "GET_MANAGE_ELECTION_REQUEST";
export const GET_MANAGE_ELECTION_SUCCESS = "GET_MANAGE_ELECTION_SUCCESS";
export const GET_MANAGE_ELECTION_FAILURE = "GET_MANAGE_ELECTION_FAILURE";

export const POST_UPDATE_MANAGE_ELECTIONS_BY_ID_REQUEST =
  "POST_UPDATE_MANAGE_ELECTIONS_BY_ID_REQUEST";
export const POST_UPDATE_MANAGE_ELECTIONS_BY_ID_SUCCESS =
  "POST_UPDATE_MANAGE_ELECTIONS_BY_ID_SUCCESS";
export const POST_UPDATE_MANAGE_ELECTIONS_BY_ID_FAILURE =
  "POST_UPDATE_MANAGE_ELECTIONS_BY_ID_FAILURE";

export const POST_ADD_CANDIDATE_TOE_BY_ID_REQUEST =
  "POST_ADD_CANDIDATE_TOE_BY_ID_REQUEST";
export const POST_ADD_CANDIDATE_TOE_BY_ID_SUCCESS =
  "POST_ADD_CANDIDATE_TOE_BY_ID_SUCCESS";
export const POST_ADD_CANDIDATE_TOE_BY_ID_FAILURE =
  "POST_ADD_CANDIDATE_TOE_BY_ID_FAILURE";

export const GET_MANAGE_CANDIDATE_TOE_BY_ID_REQUEST =
  "GET_MANAGE_CANDIDATE_TOE_BY_ID_REQUEST";
export const GET_MANAGE_CANDIDATE_TOE_BY_ID_SUCCESS =
  "GET_MANAGE_CANDIDATE_TOE_BY_ID_SUCCESS";
export const GET_MANAGE_CANDIDATE_TOE_BY_ID_FAILURE =
  "GET_MANAGE_CANDIDATE_TOE_BY_ID_FAILURE";

export const GET_MANAGE_USERLIST_REQUEST = "GET_MANAGE_USERLIST_REQUEST";
export const GET_MANAGE_USERLIST_SUCCESS = "GET_MANAGE_USERLIST_SUCCESS";
export const GET_MANAGE_USERLIST_FAILURE = "GET_MANAGE_USERLIST_FAILURE";

export const GET_USERDETAILS_BYID_REQUEST = "GET_USERDETAILS_BYID_REQUEST";
export const GET_USERDETAILS_BYID_SUCCESS = "GET_USERDETAILS_BYID_SUCCESS";
export const GET_USERDETAILS_BYID_FAILURE = "GET_USERDETAILS_BYID_FAILURE";

// export const GET_GetSecurityLevels_REQUEST = "GET_GetSecurityLevels_REQUEST";
// export const GET_GetSecurityLevels_SUCCESS = "GET_GetSecurityLevels_SUCCESS";
// export const GET_GetSecurityLevels_FAILURE = "GET_GetSecurityLevels_FAILURE";

export const GET_DATAELECTIONRESULTS_BYID_REQUEST =
  "GET_DATAELECTIONRESULTS_BYID_REQUEST";
export const GET_DATAELECTIONRESULTS_BYID_SUCCESS =
  "GET_DATAELECTIONRESULTS_BYID_SUCCESS";
export const GET_DATAELECTIONRESULTS_BYID_FAILURE =
  "GET_DATAELECTIONRESULTS_BYID_FAILURE";

export const UPDATE_SecurityLevels_REQUEST = "UPDATE_SecurityLevels_REQUEST";
export const UPDATE_SecurityLevels_SUCCESS = "UPDATE_SecurityLevels_SUCCESS";
export const UPDATE_SecurityLevels_FAILURE = "UPDATE_SecurityLevels_FAILURE";

export const DEL_MANAGE_USERLIST_BY_ID_REQUEST =
  "DEL_MANAGE_USERLIST_BY_ID_REQUEST";
export const DEL_MANAGE_USERLIST_BY_ID_SUCCESS =
  "DEL_MANAGE_USERLIST_BY_ID_SUCCESS";
export const DEL_MANAGE_USERLIST_BY_ID_FAILURE =
  "DEL_MANAGE_USERLIST_BY_ID_FAILURE";

export const POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_REQUEST =
  "POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_REQUEST";
export const POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_SUCCESS =
  "POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_SUCCESS";
export const POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_FAILURE =
  "POST_UPDATE_PAST_ELECTIONRESULTS_BY_ID_FAILURE";

export const GET_ALL_BLOG_CATEGORY_REQUEST = "GET_ALL_BLOG_CATEGORY_REQUEST";
export const GET_ALL_BLOG_CATEGORY_SUCCESS = "GET_ALL_BLOG_CATEGORY_SUCCESS";
export const GET_ALL_BLOG_CATEGORY_FAILURE = "GET_ALL_BLOG_CATEGORY_FAILURE";

export const ADD_BLOG_CATEGORY_REQUEST = "ADD_BLOG_CATEGORY_REQUEST";
export const ADD_BLOG_CATEGORY_SUCCESS = "ADD_BLOG_CATEGORY_SUCCESS";
export const ADD_BLOG_CATEGORY_FAILURE = "ADD_BLOG_CATEGORY_FAILURE";

export const POST_UPDATE_BLOG_CATEGORY_BY_ID_REQUEST =
  "POST_UPDATE_BLOG_CATEGORY_BY_ID_REQUEST";
export const POST_UPDATE_BLOG_CATEGORY_BY_ID_SUCCESS =
  "POST_UPDATE_BLOG_CATEGORY_BY_ID_SUCCESS";
export const POST_UPDATE_BLOG_CATEGORY_BY_ID_FAILURE =
  "POST_UPDATE_BLOG_CATEGORY_BY_ID_FAILURE";

export const GET_BLOGCATEGORY_BYID_REQUEST = "GET_BLOGCATEGORY_BYID_REQUEST";
export const GET_BLOGCATEGORY_BYID_SUCCESS = "GET_BLOGCATEGORY_BYID_SUCCESS";
export const GET_BLOGCATEGORY_BYID_FAILURE = "GET_BLOGCATEGORY_BYID_FAILURE";

export const DELETE_BLOGCATEGORY_REQUEST = "DELETE_BLOGCATEGORY_REQUEST";
export const DELETE_BLOGCATEGORY_SUCCESS = "DELETE_BLOGCATEGORY_SUCCESS";
export const DELETE_BLOGCATEGORY_FAILURE = "DELETE_BLOGCATEGORY_FAILURE";

export const GET_ALL_BLOG_CONTENT_REQUEST = "GET_ALL_BLOG_CONTENT_REQUEST";
export const GET_ALL_BLOG_CONTENT_SUCCESS = "GET_ALL_BLOG_CONTENT_SUCCESS";
export const GET_ALL_BLOG_CONTENT_FAILURE = "GET_ALL_BLOG_CONTENT_FAILURE";

export const GET_BLOGCONTENT_BYID_REQUEST = "GET_BLOGCONTENT_BYID_REQUEST";
export const GET_BLOGCONTENT_BYID_SUCCESS = "GET_BLOGCONTENT_BYID_SUCCESS";
export const GET_BLOGCONTENT_BYID_FAILURE = "GET_ALL_BLOG_CONTENT_FAILURE";

export const ADD_BLOG_CONTENT_REQUEST = "ADD_BLOG_CONTENT_REQUEST";
export const ADD_BLOG_CONTENT_SUCCESS = "ADD_BLOG_CONTENT_SUCCESS";
export const ADD_BLOG_CONTENT_FAILURE = "ADD_BLOG_CONTENT_FAILURE";

export const POST_UPDATE_BLOG_CONTENT_BY_ID_REQUEST =
  "POST_UPDATE_BLOG_CONTENT_BY_ID_REQUEST";
export const POST_UPDATE_BLOG_CONTENT_BY_ID_SUCCESS =
  "POST_UPDATE_BLOG_CONTENT_BY_ID_SUCCESS";
export const POST_UPDATE_BLOG_CONTENT_BY_ID_FAILURE =
  "POST_UPDATE_BLOG_CONTENT_BY_ID_FAILURE";

export const DELETE_BLOGCONTENT_REQUEST = "DELETE_BLOGCONTENT_REQUEST";
export const DELETE_BLOGCONTENT_SUCCESS = "DELETE_BLOGCONTENT_SUCCESS";
export const DELETE_BLOGCONTENT_FAILURE = "DELETE_BLOGCONTENT_FAILURE";

export const GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_REQUEST = "GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_REQUEST";
export const GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_SUCCESS = "GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_SUCCESS";
export const GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_FAILURE = "GET_ALL_PRESIDENTIAL_ELECTION_RESULTS_BY_ID_FAILURE";

export const GET_ALL_ODEMOCARCY_POLL_RESULT_REQUEST = "GET_ALL_ODEMOCARCY_POLL_RESULT_REQUEST";
export const GET_ALL_ODEMOCARCY_POLL_RESULT_SUCCESS = "GET_ALL_ODEMOCARCY_POLL_RESULT_SUCCESS";
export const GET_ALL_ODEMOCARCY_POLL_RESULT_FAILURE = "GET_ALL_ODEMOCARCY_POLL_RESULT_FAILURE";

export const LOGOUT_ADMIN = "LOGOUT_ADMIN";
