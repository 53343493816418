import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaLocationArrow } from "react-icons/fa6";
import { Col, Container, Row } from "react-bootstrap";
import { indexParties } from "../../../redux/adminone/action";
import { cApiUrlPrefix } from "../../../config/envConfig";
import disImg from "../../../assets/images/sreelamkaImg.png";
import Loader from "../../loaders/loader";
import SolidBlueBtn from "../../buttons/solidBlueBtn";
import { HiArrowNarrowRight } from "react-icons/hi";
import CardSeven from "../../cards/cardSeven";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const ChooseParty = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setUserData] = useState(null);
  const [activeCardId, setActiveCardId] = useState(null); // Track the active card

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserData(parsedUser);
    }
  }, []);

  useEffect(() => {
    dispatch(indexParties());
  }, [dispatch]);

  const indexPartiesData = useSelector(
    (state) => state.adminone.indexPartiesData?.data
  );
  const loading = useSelector((state) => state.adminone.loading);

  const data =
    indexPartiesData?.map((party) => ({
      id: party.id,
      name: party.party_name,
      image: party.logo === "0" ? disImg : cApiUrlPrefix + party.logo,
    })) || [];

  const handleCardClick = (id) => {
    setActiveCardId(id);
  };

  const handleButtonClick = () => {
    if (activeCardId) {
      const selectedParty = data.find((p) => p.id === activeCardId);
      const { electionTypeId, title } = location.state || {};
      if (selectedParty) {
        navigate("/choose-party-candidate", {
          state: {
            electionTypeId,
            title,
            id: selectedParty.id,
            party_name: selectedParty.name,
          },
        });
      }
    } else {
      alert("Please select a party before proceeding.");
    }
  };

  // const user = JSON.parse(localStorage.getItem("user"));
  // const activeId = user?.id;

  return (
    <div className="choose-party-parliament">
      <div className="d-lg-flex d-block justify-content-between align-items-center first-div">
        <div className="hero-h5">
          <h5>
            Choose party for {location.state?.title || "Election Title"}
            <span className="ps-2">(Poll)</span>
          </h5>
          <p>
            Sri Lanka, {userData?.district_name},{" "}
            {userData?.polling_division_name}{" "}
            <FaLocationArrow
              className="location-i"
              onClick={() => {
                navigate(`/select-election`);
              }}
            />
          </p>
        </div>
      </div>
      <Container fluid className="layOut-one-container">
        <div className="border-container">
          <Row className="layOut-one-row" md={5}>
            {data.map((data) => (
              <Col key={data.id} className="layOut-one-col">
                <CardSeven
                  className={`card-seven ${
                    data.id === activeCardId ? "active-card" : ""
                  }`}
                  image={data.image}
                  title={data.name}
                  onClick={() => handleCardClick(data.id)} // Just set the activeCardId
                  isActive={data.id === activeCardId}
                />
              </Col>
            ))}
          </Row>
          <Row className="button-wrapper">
            <SolidBlueBtn
              type="submit"
              className="solidBlueBtn"
              onClick={handleButtonClick}
            >
              Choose Party Candidate
              <span className="arrowBtn">
                <HiArrowNarrowRight />
              </span>
            </SolidBlueBtn>
          </Row>
        </div>
      </Container>
      {loading ? (
        <Loader color={"#0E76A8"} loading={loading} css={override} size={75} />
      ) : null}
    </div>
  );
};

export default ChooseParty;
