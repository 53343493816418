import React, { useEffect, useState } from 'react'
import ManagePresidentialElectionMain from './managePresidentialElectionMain'
import { useDispatch, useSelector } from 'react-redux';
import { getAllPresidentialElectionResultsById, indexDistrict, showAllPollingDivisionsByDistrictById } from '../../../redux/adminone/action';
import DropDown from '../../../components/dropdown/dropDown';
import { useLocation, useNavigate } from 'react-router-dom';

const options = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
];
const cstomStyle = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        color: "#65676B",
        maxWidth: "97px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#efefef" : "white",
        color: state.isFocused ? "#65676B" : "#65676B",
        padding: 5,
    }),
};
const cstomStyle1 = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        color: "#65676B",
        maxWidth: "250px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#efefef" : "white",
        color: state.isFocused ? "#65676B" : "#65676B",
        padding: 5,
    }),
};
const cstomStyle2 = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        color: "#65676B",
        maxWidth: "97px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#efefef" : "white",
        color: state.isFocused ? "#65676B" : "#65676B",
        padding: 5,
    }),
};

const ManagePresidentialElectionPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isSearchable] = useState(false);
    const [options1, setOptions1] = useState([]);
    const [options2, setOptions2] = useState([]);
    const [selectedDistrictId, setSelectedDistrictId] = useState(null);
    const [selectedPollingDivId, setSelectedPollingDivId] = useState(null);

    const { electionTypeId } = location.state || {};
  


    useEffect(() => {
        dispatch(getAllPresidentialElectionResultsById(electionTypeId));
        dispatch(indexDistrict());
    }, [dispatch]);

    const getAllPresidentialElectionResultsByIdData = useSelector((state) => state.adminone.getAllPresidentialElectionResultsByIdData?.data);

    const indexDistrictsData = useSelector((state) => state.adminone.indexDistrictsData?.data);
    useEffect(() => {
        if (indexDistrictsData) {
            const newOptions = [
                ...indexDistrictsData.map((item) => ({
                    value: item.id,
                    label: item.district_name,
                })),
            ];
            setOptions1(newOptions);
        }
    }, [indexDistrictsData]);

    const selectChange = (selectedOption) => {
        setSelectedDistrictId(selectedOption.value);
        dispatch(showAllPollingDivisionsByDistrictById(selectedOption.value));
    };
    const showsAllPollingDivisionsByDistrictIdData = useSelector(
        (state) => state.adminone.showsAllPollingDivisionsByDistrictIdData?.data
    );
    useEffect(() => {
        if (showsAllPollingDivisionsByDistrictIdData) {
            const newOptions = [
                ...showsAllPollingDivisionsByDistrictIdData.map((item) => ({
                    value: item.id,
                    label: item.polling_division_name,
                })),
            ];
            setOptions2(newOptions);
        }
    }, [showsAllPollingDivisionsByDistrictIdData]);

    const selectChange1 = (selectedOption) => {
        setSelectedPollingDivId(selectedOption.value);
    };

    const loading = useSelector((state) => state.adminone.loading);
    const error = useSelector((state) => state.adminone.error);
    const dropdownItems = (data) => [
        { label: "Delete", href: "#/delete" },
    ];
    const labels = [
        "User Name",
        "Address",
        "Voter Id",
        "1st Prefrence",
        "2st Prefrence",
        "3st Prefrence",
        "Action",
    ];

    const data = Array.isArray(getAllPresidentialElectionResultsByIdData)
        ? getAllPresidentialElectionResultsByIdData.map((presiResData) => ({
            "User Name": presiResData.user_name,
            "Address": presiResData.address,
            "Voter Id": presiResData.voter_id,
            "1st Prefrence": presiResData.preference1,
            "2st Prefrence": presiResData.preference2,
            "3st Prefrence": presiResData.preference3,
            Action: (
                <DropDown
                    className="sequritiesPartyAction ms-auto"
                    Children={"Action"}
                    items={dropdownItems(presiResData)}
                />
            ),
        }))
        : [];

    const handleClick = () => {
        navigate("/presidential-election-poll-result", { state: { electionTypeId } });
    };

    const props = {
        pageTitles: {
            pageTitle1: "Manage Presidential Election",
        },
        childrens: {
            children1: "O'Democracy Poll Result",
        },
        className: {
            cselectpageSize: "cselectpageSize",
            cselectpageSize2: "cselectpageSize2",
            minputSearch: "minputSearch",
            deleteModal: "deleteModal",
        },

        label: {
            lable1: "",
            lable2: "",
            lable3: "",
        },

        details: {
            detail1: "",
            detail2: "",
            detail3: "",
        },
        Placeholder: {
            Placeholder1: "Search",
            Placeholder2: "Districts",
            Placeholder3: "Polling Divisions",
        },
        options,
        options1,
        options2,
        isSearchable,
        cstomStyle,
        cstomStyle1,
        cstomStyle2,
        handleClick,
        labels,
        data,
        loading,
        error,
        selectChange,
        selectChange1
    };
    return (
        <ManagePresidentialElectionMain {...props} />
    )
}

export default ManagePresidentialElectionPage
