import React from "react";
import OrangeSolidBtn from "../buttons/orangeSolidBtn";

const CardTwo = (props) => {
    const {
        title,
        src,
        bio,
        childrens,
        position,
        party_name,
        date_of_birth,
        headings,
        onClick,
    } = props;
    return (
        <div className="two-card card">
            <div className="row g-0">
                <div className="col-md-1">
                    <div className="card-img-blk">
                        <img className="img" src={src} alt="..." />
                    </div>
                </div>
                <div className="col-md-11">
                    <div className="card-body">
                        <div className="card-title-blk">
                            <h3 className="card-title">{title}</h3>
                            <p className="card-text">{bio}</p>
                        </div>

                        <div className="card-candidate-btn-blk">
                            <aside className="card-aside">
                                <h4 className="aside-heading">{headings.heading1}</h4>
                                <p className="aside-details">{position}</p>
                            </aside>
                            <aside className="card-aside">
                                <h4 className="aside-heading">{headings.heading2}</h4>
                                <p className="aside-details">{party_name}</p>
                            </aside>
                            <aside className="card-aside">
                                <h4 className="aside-heading">{headings.heading3}</h4>
                                <p className="aside-details">{date_of_birth}</p>
                            </aside>
                            <aside className="card-aside">
                                <OrangeSolidBtn
                                    onClick={onClick}
                                    children={childrens?.children1}
                                />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardTwo;
