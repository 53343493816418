import React from 'react'
import OrangeSolidBtn from '../buttons/orangeSolidBtn'

const CardThree = (props) => {
    const { title, ideology, leader, headquarters, party_abbreviation, website, src, founding_date, childrens, headings, onClick } = props;
    return (
        <div className="three-card card">
            <div className="row g-0">
                <div className="col-md-1">
                    <div className="card-img-blk">
                        <img className="img-fluid" src={src} alt="..." />
                    </div>
                </div>
                <div className="col-md-11">
                    <div className="card-body">
                        <div className="card-title-blk">
                            <h3 className="card-title">{title} - {party_abbreviation}</h3>
                            <p className="card-text">{headquarters}</p>
                            <p className="card-text">{ideology}</p>
                        </div>

                        <div className="">
                            <aside className="card-aside">
                                <h4 className="aside-heading"></h4>
                                <p className="aside-details"> <span className="aside-icon"></span></p>
                            </aside>
                            <aside className="card-aside">
                                <h4 className="aside-heading"></h4>
                                <p className="aside-details"><span className="aside-icon"></span></p>
                            </aside>
                        </div>
                        <div className="card-party-btn-blk">
                            <aside className="card-aside">
                                <h4 className="aside-heading">{headings.heading1}</h4>
                                <p className="aside-details">{leader}</p>
                            </aside>
                            <aside className="card-aside">
                                <h4 className="aside-heading">{headings.heading2}</h4>
                                <p className="aside-details">{founding_date}</p>
                            </aside>
                            <aside className="card-aside">
                                <h4 className="aside-heading">{headings.heading3}</h4>
                                <p className="aside-details">{website}</p>
                            </aside>
                            <aside className="card-aside">
                                <OrangeSolidBtn
                                    children={childrens?.children1}
                                    onClick={onClick}
                                />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardThree
